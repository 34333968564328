/* eslint-disable */
import { MedicalQuestionWriteNumeric } from "../../@types/medicalQuestion"
import { ChangeEvent, Dispatch, SetStateAction, useState } from "react"
import { GeneralAnswers } from "../../@types/patient"
import { Box, FormControl, Grid, Paper, TextField, Typography } from "@mui/material"

type RenderMedicalQuestionWriteNumericProps = {
  question: MedicalQuestionWriteNumeric
  answersForm: GeneralAnswers[]
  setAnswersForm: Dispatch<SetStateAction<GeneralAnswers[]>>
  currentQuestionnaireID: string
}

const RenderMedicalQuestionWriteNumeric = ({
  question,
  answersForm,
  setAnswersForm,
  currentQuestionnaireID,
}: RenderMedicalQuestionWriteNumericProps) => {
  const [isError, setIsError] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>("")

  const handleAnswerChange = (event: ChangeEvent<HTMLInputElement>) => {
    //console.log("event.target.value:", event.target.value)
    //console.log(answersForm.flatMap((answers) => answers.answers.filter((answer) => answer.id === question.id))[0].answer)
    //console.log(`question.minValue: ${question.minValue}     question.maxValue: ${question.maxValue}`)

    // check if input value is a string
    if (isNaN(Number(event.target.value))) {
      setIsError(true)
      setErrorMessage(`Value must be a number`)
    } else {
      // check if number is between minValue and maxValue
      if (parseInt(event.target.value) < question.minValue || parseInt(event.target.value) > question.maxValue) {
        setIsError(true)
        setErrorMessage(`Value must be between ${question.minValue} and ${question.maxValue}`)
      } else {
        setIsError(false)
        setErrorMessage("")
      }
      setAnswersForm((answers: GeneralAnswers[]) => {
        // result stores all answers of all questionnaires
        const result: GeneralAnswers[] = answers.map((answer) => {
          if (answer.name === currentQuestionnaireID) {
            // current answers in the current questionnaire
            const updatedAnswers = answer.answers.map((answer) => {
              if (answer.id === question.id) {
                return { ...answer, answer: event.target.value }
              } else {
                return answer
              }
            })
            return { name: currentQuestionnaireID, answers: updatedAnswers, time: "" }
          } else {
            return answer
          }
        })
        return result
      })
    }
  }

  return (
    <FormControl fullWidth sx={{ pt: 2, pb: 2, width: "100%" }} size="small">
      <Paper elevation={3}>
        {" "}
        {/* puts the component a bit higher like several papers on top of each other */}
        <Grid container spacing={0} sx={{ width: "100%", pt: 1, pb: 1 }}>
          <Grid item xs={12} md={6} display="flex" justifyContent="center" alignContent="center" alignItems="flex-start">
            <Box width="80%" sx={{ pt: 1 }}>
              <Typography textAlign="center">{question.description}</Typography>
            </Box>
          </Grid>

          <Grid item xs={12} md={6} display="flex" justifyContent="center" alignContent="center" alignItems="center">
            <Box width="90%" sx={{ textJustify: "left" }}>
              <TextField
                error={isError}
                helperText={errorMessage}
                id={question.id}
                placeholder="Ex. 1234"
                sx={{ width: "100%" }}
                size="small"
                onChange={handleAnswerChange}
                //inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                //type="number"
                value={answersForm.flatMap((answers) => answers.answers.filter((answer) => answer.id === question.id))[0].answer}
              ></TextField>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </FormControl>
  )
}

export default RenderMedicalQuestionWriteNumeric
