import { Categorical, MedicalQuestion } from "../../../../@types/medicalQuestion"

const category: Categorical[] = [
  { label: "0", value: "0" },
  { label: "1", value: "1" },
  { label: "2", value: "2" },
  { label: "3", value: "3" },
  { label: "4", value: "4" },
  { label: "5", value: "5" },
]

const catDE: MedicalQuestion[] = [
  {
    id: "cat_intro",
    name: "intro",
    value:
      "Dieser Fragebogen wird Ihnen und Ihrem Arzt helfen, die Auswirkungen der COPD (chronisch obstruktive Lungenerkrankung) " +
      "auf Ihr Wohlbefinden und Ihr tägliches Leben festzustellen. Bitte geben Sie für jede der folgenden Aussagen die Zahl an, " +
      "die derzeit am besten auf Sie zutrifft.",
    type: "intro",
    lang: "de",
    parent: "cat",
    required: false,
  },
  {
    id: "cat_1",
    name: "COPD assessment test",
    type: "categoricalLong",
    value: category,
    labelStart: "Ich huste nie",
    labelEnd: "Ich huste ständig",
    parent: "cat",
    order: 1,
    lang: "de",
    required: false,
  },
  {
    id: "cat_2",
    name: "COPD assessment test",
    type: "categoricalLong",
    value: category,
    labelStart: "Ich bin überhaupt nicht verschleimt",
    labelEnd: "Ich bin völlig verschleimt",
    parent: "cat",
    order: 2,
    lang: "de",
    required: false,
  },
  {
    id: "cat_3",
    name: "COPD assessment test",
    type: "categoricalLong",
    value: category,
    labelStart: "Ich spüre keinerlei Engegefühl in der Brust",
    labelEnd: "Ich spüre ein sehr starkes Engegefühl in der Brust",
    parent: "cat",
    order: 3,
    lang: "de",
    required: false,
  },
  {
    id: "cat_4",
    name: "COPD assessment test",
    type: "categoricalLong",
    value: category,
    labelStart: "Wenn ich bergauf oder eine Treppe hinaufgehe, komme ich nicht außer Atem",
    labelEnd: "Wenn ich bergauf oder eine Treppe hinaufgehe, komme ich sehr außer Atem",
    parent: "cat",
    order: 4,
    lang: "de",
    required: false,
  },
  {
    id: "cat_5",
    name: "COPD assessment test",
    type: "categoricalLong",
    value: category,
    labelStart: "Ich bin bei meinen häuslichen Aktivitäten nicht eingeschränkt",
    labelEnd: "Ich bin bei meinen häuslichen Aktivitäten sehr stark eingeschränkt",
    parent: "cat",
    order: 5,
    lang: "de",
    required: false,
  },
  {
    id: "cat_6",
    name: "COPD assessment test",
    type: "categoricalLong",
    value: category,
    labelStart: "Ich habe keine Bedenken, trotz meiner Lungenerkrankung das Haus zu verlassen",
    labelEnd: "Ich habe wegen meiner Lungenerkrankung große Bedenken, das Haus zu verlassen",
    parent: "cat",
    order: 6,
    lang: "de",
    required: false,
  },
  {
    id: "cat_7",
    name: "COPD assessment test",
    type: "categoricalLong",
    value: category,
    labelStart: "Ich schlafe tief und fest",
    labelEnd: "Wegen meiner Lungenerkrankung schlafe ich nicht tief und fest",
    parent: "cat",
    order: 7,
    lang: "de",
    required: false,
  },
  {
    id: "cat_8",
    name: "COPD assessment test",
    type: "categoricalLong",
    value: category,
    labelStart: "Ich bin voller Energie",
    labelEnd: "Ich habe überhaupt keine Energie",
    parent: "cat",
    order: 8,
    lang: "de",
    required: false,
  },
]

export default catDE
