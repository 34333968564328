import { MixedQuestionRecording } from "../../../../@types/mixedQuestionRecording"

const culturalStoryEN: MixedQuestionRecording = {
  questionnaires: [
    {
      id: "cs_q1",
      name: "Event",
      type: "freeText",
      description: "Event",
      parent: "cs",
      order: 1,
      lang: "en",
      required: true,
    },
    {
      id: "cs_q2",
      name: "Title of event",
      type: "freeText",
      description: "Title of event",
      parent: "cs",
      order: 2,
      lang: "en",
      required: true,
    },
    {
      id: "cs_q3",
      name: "Common event",
      type: "number",
      minValue: 1,
      maxValue: 100,
      description: "How common is the event?",
      info: "Out of 100 people, how many will experience this event at least once during their lives?",
      parent: "cs",
      order: 3,
      lang: "en",
      required: true,
    },
    {
      id: "cs_q4",
      name: "Importance of event",
      type: "categoricalLong",
      value: [
        { label: "1", value: "1" },
        { label: "2", value: "2" },
        { label: "3", value: "3" },
        { label: "4", value: "4" },
        { label: "5", value: "5" },
        { label: "6", value: "6" },
        { label: "7", value: "7" },
      ],
      labelStart: "Unimportant",
      labelEnd: "Greatest importance",
      description: "How important is the event?",
      parent: "cs",
      order: 4,
      lang: "en",
      required: false,
    },
    {
      id: "cs_q5",
      name: "Common event",
      type: "number",
      minValue: 1,
      maxValue: 100,
      description: "At what age is the event expected to take place?",
      parent: "cs",
      order: 5,
      lang: "en",
      required: true,
    },
    {
      id: "cs_q6",
      name: "Importance of event",
      type: "categoricalLong",
      value: [
        { label: "-3", value: "-3" },
        { label: "-2", value: "-2" },
        { label: "-1", value: "-1" },
        { label: "0", value: "0" },
        { label: "1", value: "1" },
        { label: "2", value: "2" },
        { label: "3", value: "3" },
      ],
      labelStart: "Very negative",
      labelEnd: "Very positive",
      description: "Is the event emotionally positive or negative?",
      parent: "cs",
      order: 6,
      lang: "en",
      required: false,
    },
  ],
  recordings: [],
}

export default culturalStoryEN
