import futureProjectionEN from "./future-projection_en"
import futureProjectionFR from "./future-projection_fr"
import { MixedQuestionRecording } from "../../../../@types/mixedQuestionRecording"

const futureProjection: MixedQuestionRecording = {
  questionnaires: [...futureProjectionEN.questionnaires, ...futureProjectionFR.questionnaires],
  recordings: [...futureProjectionEN.recordings, ...futureProjectionFR.recordings],
}

export default futureProjection
