import { MedicalQuestion } from "../../../../@types/medicalQuestion"

const actEN: MedicalQuestion[] = [
  {
    id: "act_1",
    name: "Asthma control test",
    type: "categoricalShort",
    value: [
      {
        label: "All of the time",
        value: "all-of-the-time",
      },
      {
        label: "Most of the time",
        value: "most-of-the-time",
      },
      {
        label: "Some of the time",
        value: "some-of-the-time",
      },
      {
        label: "A little of the time",
        value: "a-little-of-the-time",
      },
      {
        label: "None of the time",
        value: "none-of-the-time",
      },
    ],
    description:
      "During the last 4 weeks, how much of the time has your asthma kept you from getting as much done at " + "work, school or home?",
    parent: "act",
    order: 1,
    lang: "en",
    required: true,
  },
  {
    id: "act_2",
    name: "Asthma control test",
    type: "categoricalShort",
    value: [
      {
        label: "More than once a day",
        value: "more-than-once-per-day",
      },
      {
        label: "Once a day",
        value: "once-per-day",
      },
      {
        label: "3 to 6 times a week",
        value: "three-to-six-per-week",
      },
      {
        label: "Once or twice a week",
        value: "once-or-twice-per-week",
      },
      {
        label: "Not at all",
        value: "never",
      },
    ],
    description: "During the last 4 weeks, how often have you had shortness of breath?",
    parent: "act",
    order: 2,
    lang: "en",
    required: true,
  },
  {
    id: "act_3",
    name: "Asthma control test",
    type: "categoricalShort",
    value: [
      {
        label: "4 or more nights a week",
        value: "four-nights-or-more-per-week",
      },
      {
        label: "2 to 3 nights a week",
        value: "two-to-three-nights-per-week",
      },
      {
        label: "Once a week",
        value: "one-night-per-week",
      },
      {
        label: "Once or Twice",
        value: "one-or-two",
      },
      {
        label: "Not at all",
        value: "never",
      },
    ],
    description:
      "During the last 4 weeks, how often have your asthma symptoms (wheezing, coughing, shortness of breath," +
      " chest tightness or pain) woken you up at night or earlier than usual in the morning?",
    parent: "act",
    order: 3,
    lang: "en",
    required: true,
  },
  {
    id: "act_4",
    name: "Asthma control test",
    type: "categoricalShort",
    value: [
      {
        label: "3 or more times per day",
        value: "three-times-or-more-per-day",
      },
      {
        label: "Once or twice per day",
        value: "once-or-twice-per-day",
      },
      {
        label: "2 or 3 times per week",
        value: "two-or-three-times-per-week",
      },
      {
        label: "Once a week or less",
        value: "one-or-less-per-week",
      },
      {
        label: "Not at all",
        value: "never",
      },
    ],
    description: "During the last 4 weeks, how often have you used your rescue inhaler or nebuliser medication " + "(such as Salbutamol)?",
    parent: "act",
    order: 4,
    lang: "en",
    required: true,
  },
  {
    id: "act_5",
    name: "Asthma control test",
    type: "categoricalShort",
    value: [
      {
        label: "Not controlled at all",
        value: "not-controlled-at-all",
      },
      {
        label: "Poorly controlled",
        value: "poorly-controlled",
      },
      {
        label: "Somewhat controlled",
        value: "somewhat-controlled",
      },
      {
        label: "Well controlled",
        value: "well-controlled",
      },
      {
        label: "Completely controlled",
        value: "completely-controlled",
      },
    ],
    description: "How would you rate your asthma control during the last 4 weeks?",
    parent: "act",
    order: 5,
    lang: "en",
    required: true,
  },
]

export default actEN
