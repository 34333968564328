import { MixedQuestionRecording } from "../../../../@types/mixedQuestionRecording"
import culturalStoryEN from "./cultural-story_en"
import culturalStoryFR from "./cultural-story_fr"

const culturalStory: MixedQuestionRecording = {
  questionnaires: [...culturalStoryEN.questionnaires, ...culturalStoryFR.questionnaires],
  recordings: [...culturalStoryEN.recordings, ...culturalStoryFR.recordings],
}

export default culturalStory
