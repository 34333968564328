import { MixedQuestionRecording } from "../../../../@types/mixedQuestionRecording"

const futureProjectionFR: MixedQuestionRecording = {
  questionnaires: [
    {
      id: "fp_q2",
      name: "Title of event",
      type: "freeText",
      description: "Titre de l’évènement",
      parent: "fp",
      order: 1,
      lang: "fr",
      required: true,
    },
    {
      id: "fp_q3",
      name: "Age",
      type: "number",
      minValue: 1,
      maxValue: 100,
      description: "Mon âge au moment de l’événement",
      parent: "fp",
      order: 2,
      lang: "fr",
      required: true,
    },
    {
      id: "fp_q4",
      name: "Memory of event",
      type: "categoricalLong",
      value: [
        { label: "1", value: "1" },
        { label: "2", value: "2" },
        { label: "3", value: "3" },
        { label: "4", value: "4" },
        { label: "5", value: "5" },
        { label: "6", value: "6" },
        { label: "7", value: "7" },
      ],
      labelStart: "Négatives",
      labelEnd: "Positives",
      description: "Mes émotions en lien avec le souvenir sont :",
      parent: "fp",
      order: 3,
      lang: "fr",
      required: false,
    },
  ],
  recordings: [
    {
      id: "fp_q1",
      name: "Future projection recording",
      type: "text",
      media: undefined,
      description: "Raconter à l'oral le 1er évènement",
      lang: "fr",
    },
  ],
}

export default futureProjectionFR
