import { MixedQuestionRecording } from "../../../../@types/mixedQuestionRecording"

const selfDefiningMemoryFR: MixedQuestionRecording = {
  questionnaires: [
    {
      id: "sdm_q2",
      name: "Title of event",
      type: "freeText",
      description: "Titre de l'évènement",
      parent: "sdm",
      order: 1,
      lang: "fr",
      required: true,
    },
    {
      id: "sdm_q3",
      name: "Title of event",
      type: "number",
      minValue: 1,
      maxValue: 100,
      description: "Mon âge au moment de l'événement",
      parent: "sdm",
      order: 2,
      lang: "fr",
      required: true,
    },
    {
      id: "sdm_q4",
      name: "Memory of event",
      type: "categoricalLong",
      value: [
        { label: "1", value: "1" },
        { label: "2", value: "2" },
        { label: "3", value: "3" },
        { label: "4", value: "4" },
        { label: "5", value: "5" },
        { label: "6", value: "6" },
        { label: "7", value: "7" },
      ],
      labelStart: "Vague",
      labelEnd: "Très détaillé",
      description: "Mon souvenir de l'évènement est :",
      parent: "sdm",
      order: 3,
      lang: "fr",
      required: false,
    },
    {
      id: "sdm_q5",
      name: "My emotions",
      type: "categoricalLong",
      value: [
        { label: "1", value: "1" },
        { label: "2", value: "2" },
        { label: "3", value: "3" },
        { label: "4", value: "4" },
        { label: "5", value: "5" },
        { label: "6", value: "6" },
        { label: "7", value: "7" },
      ],
      labelStart: "Négatives",
      labelEnd: "Positives",
      description: "Mes émotions en lien avec le souvenir sont :",
      parent: "sdm",
      order: 4,
      lang: "fr",
      required: false,
    },
    {
      id: "sdm_q6",
      name: "My emotions intense",
      type: "categoricalLong",
      value: [
        { label: "1", value: "1" },
        { label: "2", value: "2" },
        { label: "3", value: "3" },
        { label: "4", value: "4" },
        { label: "5", value: "5" },
        { label: "6", value: "6" },
        { label: "7", value: "7" },
      ],
      labelStart: "Pas Intenses",
      labelEnd: "Très Intenses",
      description: "Mes émotions en lien avec le souvenir sont :",
      parent: "sdm",
      order: 5,
      lang: "fr",
      required: false,
    },
    {
      id: "sdm_q7",
      name: "event part of life",
      type: "categoricalLong",
      value: [
        { label: "1", value: "1" },
        { label: "2", value: "2" },
        { label: "3", value: "3" },
        { label: "4", value: "4" },
        { label: "5", value: "5" },
      ],
      labelStart: "Complètement en désaccord",
      labelEnd: "Complètement d'accord",
      description: "Je ressens que cet événement est devenu une partie de mon identité",
      parent: "sdm",
      order: 6,
      lang: "fr",
      required: false,
    },
    {
      id: "sdm_q8",
      name: "event part of life",
      type: "categoricalLong",
      value: [
        { label: "1", value: "1" },
        { label: "2", value: "2" },
        { label: "3", value: "3" },
        { label: "4", value: "4" },
        { label: "5", value: "5" },
      ],
      labelStart: "Complètement en désaccord",
      labelEnd: "Complètement d'accord",
      description:
        "Cet événement est devenu un point de référence pour ma manière de me comprendre moi-même et de " + "comprendre le monde",
      parent: "sdm",
      order: 7,
      lang: "fr",
      required: false,
    },
    {
      id: "sdm_q9",
      name: "event part of life",
      type: "categoricalLong",
      value: [
        { label: "1", value: "1" },
        { label: "2", value: "2" },
        { label: "3", value: "3" },
        { label: "4", value: "4" },
        { label: "5", value: "5" },
      ],
      labelStart: "Complètement en désaccord",
      labelEnd: "Complètement d'accord",
      description: "Je ressens que cet événement est devenu une partie centrale de mon histoire de vie",
      parent: "sdm",
      order: 8,
      lang: "fr",
      required: false,
    },
    {
      id: "sdm_q10",
      name: "event part of life",
      type: "categoricalLong",
      value: [
        { label: "1", value: "1" },
        { label: "2", value: "2" },
        { label: "3", value: "3" },
        { label: "4", value: "4" },
        { label: "5", value: "5" },
      ],
      labelStart: "Complètement en désaccord",
      labelEnd: "Complètement d'accord",
      description: "Cet événement a coloré ma façon de penser et de ressentir d’autres expériences",
      parent: "sdm",
      order: 9,
      lang: "fr",
      required: false,
    },
    {
      id: "sdm_q11",
      name: "event part of life",
      type: "categoricalLong",
      value: [
        { label: "1", value: "1" },
        { label: "2", value: "2" },
        { label: "3", value: "3" },
        { label: "4", value: "4" },
        { label: "5", value: "5" },
      ],
      labelStart: "Complètement en désaccord",
      labelEnd: "Complètement d'accord",
      description: "Cet événement a changé ma vie de façon définitive",
      parent: "sdm",
      order: 10,
      lang: "fr",
      required: false,
    },
    {
      id: "sdm_q12",
      name: "event part of life",
      type: "categoricalLong",
      value: [
        { label: "1", value: "1" },
        { label: "2", value: "2" },
        { label: "3", value: "3" },
        { label: "4", value: "4" },
        { label: "5", value: "5" },
      ],
      labelStart: "Complètement en désaccord",
      labelEnd: "Complètement d'accord",
      description: "Je pense souvent aux effets que cet événement aura sur mon futur",
      parent: "sdm",
      order: 11,
      lang: "fr",
      required: false,
    },
    {
      id: "sdm_q13",
      name: "event part of life",
      type: "categoricalLong",
      value: [
        { label: "1", value: "1" },
        { label: "2", value: "2" },
        { label: "3", value: "3" },
        { label: "4", value: "4" },
        { label: "5", value: "5" },
      ],
      labelStart: "Complètement en désaccord",
      labelEnd: "Complètement d'accord",
      description: "Cet événement était un tournant dans ma vie",
      parent: "sdm",
      order: 12,
      lang: "fr",
      required: false,
    },
  ],
  recordings: [
    {
      id: "sdm_q1",
      name: "Free speech answer a question",
      type: "text",
      media: undefined,
      description:
        "Décrivez un souvenir avec suffisamment de détails pour aider votre ami à voir et à ressentir ce " + "que vous avez vécu.",
      lang: "fr",
    },
  ],
}

export default selfDefiningMemoryFR
