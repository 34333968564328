import { MedicalQuestionCategoricalShort } from "../../@types/medicalQuestion"
import { GeneralAnswers } from "../../@types/patient"
import { ChangeEvent, Dispatch, SetStateAction, useState } from "react"
import { Box, Button, FormControl, Grid, Paper, Stack, TextField, Typography } from "@mui/material"

type RenderMedicalQuestionCategoricalProps = {
  question: MedicalQuestionCategoricalShort
  answersForm: GeneralAnswers[]
  setAnswersForm: Dispatch<SetStateAction<GeneralAnswers[]>>
  currentQuestionnaireID: string
}

const RenderMedicalQuestionCategoricalShort = ({
  question,
  answersForm,
  setAnswersForm,
  currentQuestionnaireID,
}: RenderMedicalQuestionCategoricalProps) => {
  const [currentFreeTextValue, setCurrentFreeTextValue] = useState<string>("")
  const handleAnswerChangeFreeText = (event: ChangeEvent<HTMLInputElement>) => {
    setCurrentFreeTextValue(event.target.value)
    handleAnswerChange(event.target.value)
  }
  const handleAnswerChange = (event: string) => {
    setAnswersForm((answers: GeneralAnswers[]) => {
      const result: GeneralAnswers[] = answers.map((answer) => {
        if (answer.name === currentQuestionnaireID) {
          const updatedAnswers = answer.answers.map((answer) => {
            if (answer.id === question.id) {
              return { ...answer, answer: event }
            } else {
              return answer
            }
          })
          return { name: currentQuestionnaireID, answers: updatedAnswers, time: "" }
        } else {
          return answer
        }
      })
      return result
    })
  }

  return (
    <FormControl fullWidth sx={{ pt: 2, pb: 2 }}>
      <Paper elevation={3}>
        <Grid container spacing={0} sx={{ width: "100%", pt: 1, pb: 1 }}>
          <Grid item xs={12} md={6} display="flex" justifyContent="center" alignContent="center" alignItems="center">
            <Box width="80%">
              <Typography textAlign="center">{question.description}</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} display="flex" justifyContent="center" alignContent="center" alignItems="center">
            <Box width="90%">
              <Stack direction="column" justifyContent="center" alignContent="center" alignItems="center">
                {question.value.map((item) => {
                  if (item.label && item.type == "textAnswer") {
                    return (
                      <Box key={item.label} width="100%" sx={{ pt: 1 }}>
                        <TextField
                          placeholder={item.label}
                          sx={{ width: "100%" }}
                          size="small"
                          onChange={handleAnswerChangeFreeText}
                          value={currentFreeTextValue}
                          // value={answersForm.flatMap((answers) => answers.answers.filter(
                          // (answer) => answer.id === question.id))[0].answer}
                        ></TextField>
                      </Box>
                    )
                  } else {
                    return (
                      <Button
                        key={item.label}
                        variant={
                          answersForm.flatMap((answers) => answers.answers.filter((answer) => answer.id === question.id))[0].answer ===
                          item.value
                            ? "contained"
                            : "outlined"
                        }
                        sx={{ mt: 1 }}
                        fullWidth
                        onClick={() => {
                          handleAnswerChange(item.value)
                        }}
                      >
                        {item.label}
                      </Button>
                    )
                  }
                })}
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </FormControl>
  )
}

export default RenderMedicalQuestionCategoricalShort
