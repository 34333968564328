import { GeneralAnswers } from "../../@types/patient"
import { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from "react"
import { MedicalQuestionMulti } from "../../@types/medicalQuestion"
import { Box, Checkbox, FormControl, Grid, Paper, Stack, Typography } from "@mui/material"

type RenderMedicalQuestionMultiProps = {
  question: MedicalQuestionMulti
  answersForm: GeneralAnswers[]
  setAnswersForm: Dispatch<SetStateAction<GeneralAnswers[]>>
  currentQuestionnaireID: string
}

const RenderMedicalQuestionMulti = ({ question, answersForm, setAnswersForm, currentQuestionnaireID }: RenderMedicalQuestionMultiProps) => {
  const [currentValues, setCurrentValues] = useState<string[]>(
    answersForm.flatMap((answers) => answers.answers.filter((answer) => answer.id === question.id))[0].answer.split(";")
  )

  useEffect(() => {
    handleAnswerChangeMulti(currentValues)
  }, [currentValues])

  const handleAnswerChangeMulti = (event: string[]) => {
    const eventStringified: string = event.join(";")
    setAnswersForm((answers: GeneralAnswers[]) => {
      const result: GeneralAnswers[] = answers.map((answer) => {
        if (answer.name === currentQuestionnaireID) {
          const updatedAnswers = answer.answers.map((answer) => {
            if (answer.id === question.id) {
              return { ...answer, answer: eventStringified }
            } else {
              return answer
            }
          })

          return { name: currentQuestionnaireID, answers: updatedAnswers, time: "" }
        } else {
          return answer
        }
      })
      return result
    })
  }

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setCurrentValues((prevState) => [...prevState, event.target.name])
    } else {
      setCurrentValues((prevState) => prevState.filter((item) => item !== event.target.name))
    }
  }

  return (
    <FormControl fullWidth sx={{ pt: 3, pb: 3, width: "100%" }}>
      <Paper elevation={3}>
        <Grid container spacing={0} sx={{ width: "100%", pt: 3, pb: 3 }}>
          <Grid item xs={12} md={4} display="flex" justifyContent="center" alignContent="center" alignItems="flex-start">
            <Box width="80%" sx={{ pt: 2 }}>
              <Typography textAlign="center">{question.description}</Typography>
            </Box>
          </Grid>

          <Grid item xs={12} md={8} display="flex" justifyContent="center" alignContent="center" alignItems="center">
            <Box width="90%" sx={{ textJustify: "left" }}>
              {question.value.map((item) => (
                <Stack
                  key={item.value}
                  direction={"row"}
                  justifyContent="flex-start"
                  alignContent="flex-start"
                  alignItems="center"
                  sx={{ m: 2 }}
                >
                  <Checkbox
                    name={item.value}
                    onChange={handleCheckboxChange}
                    checked={answersForm
                      .flatMap((answers) => answers.answers.filter((answer) => answer.id === question.id))[0]
                      .answer.split(";")
                      .includes(item.value)}
                  />
                  <Typography sx={{ textAlign: "left" }}>{item.label}</Typography>
                </Stack>
              ))}
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </FormControl>
  )
}

export default RenderMedicalQuestionMulti
