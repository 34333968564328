const en = {
  menu: {
    about: "About",
    admin: "Admin",
    confirm: "Attention: Are you sure you want to return to the beginning? All data you entered will be lost.",
    language: "Select your language",
    logout: "Logout",
    voice: {
      headerName: "Voice",
      confirm: "Attention: Are you sure you want to start with the vocal tasks? ",
    },
    questionnaires: {
      headerName: "Questionnaires",
      confirm: "Attention: Are you sure you want to start with the questionnaires? ",
    },
    footer: {
      information: "Informations",
    },
  },
  project: {
    clickToStart: "Start",
    goToNextStep: "Continue",
    patientIDFootnote: "Patient ID:",
    consent: {
      title: "Consent",
      accept: "I acknowledge that I have read this document, accept its terms and therefore do not object to the Research.",
      date: "Date:",
      continue: "Validate",
    },
    usertype: {
      newUser: "This is my first participation",
      existingUser: "I have already participated and I want to participate again",
    },
    newUser: {
      title: "Please enter your email address",
      explication:
        "No message will be sent to the e-mail address you provide. It will only be used to retrieve your data for future " +
        "participation in the study or if you wish to exercise your rights.",
      //email: "To receive it, please indicate your email address below",
      //userid: "Code",
      //privacy: "Note: your email address will not be stored, it will only be used to send you this code.",
      //send: "Send code",
      continue: "Continue",
    },
    existingUser: {
      title: "Please log in with your email address",
      explication:
        "No message will be sent to the email address you have given us. It is only used to retrieve your historical" +
        " data and to enable us to apply any wishes you may have regarding the exercise of your rights.",
      continue: "Continue",
      error: "Email not existing. Please verify that the email is correct or if you have participated with this email.",
      isVerifying: "Your email is currently verified. One moment..",
    },
    phoneNumber: {
      title: "Please enter your mobile phone number",
      explication:
        "You will receive an SMS allowing you to complete this sample in the waiting room. You will receive a similar " +
        "SMS in 1 week to provide a new sample from your home, and so on until the end of the study.",
      continue: "Confirmer",
    },
    recording: {
      title: "Vocal tasks",
      instructions: {
        title: "Instructions",
        first: "Find a quiet environment",
        second: "Try to keep a constant distance from your mouth to your recording device during the vocal tasks",
        third: "Follow the instructions given for the vocal tasks",
        continue: "Continue",
      },
      tasks: "Reminding steps :",
    },
    questionnaire: {
      title: "Questionnaire",
      description: "Answer the following questions",
    },
    done: {
      questionnairesNext: {
        title: "This part is over. Thanks!",
        continue: "Continue with questionnaires",
      },
      recordingsNext: {
        title: "This part is over. Thanks! Please put the headset on your head now.",
        continue: "Continue with vocal tasks",
      },
      information:
        "If you have any questions about the study, an 'Information' menu is " +
        "available at the bottom of each page, starting on the next page.",
    },
    final: {
      title: "The study is finished, thank you for participating!",
    },
  },
  audioplayer: {
    pause: "Pause",
    stop: "Stop",
    listen: "Listen",
    record: "Record",
    recordAgain: "Record Again",
    instruction: "You can go to the next step once you have finished the task",
    rerecord: "If you like, you can re-record the audio or go to the next step",
  },
  admin: {
    title: "Questionnaire administration",
    select: "Select a patient ID to complete the questionnaire",
    table: {
      idColumnName: "ID",
      questionnairesColumnName: "Quest.",
      recordingsColumnName: "Record.",
      doctorsColumnName: "Doctor",
      completeButton: "complete",
    },
  },
  mh6: {
    sdm: {
      title: "Self-Defining Memory",
      phrase1:
        "We are going to ask you to recall, out loud, three particular memories from your life that we will call" +
        " 'self-defining memories'. A self-defining memory is a personal memory that has the following characteristics:\n",
      list: {
        1: "  1. It dates back at least a year.",
        2:
          "  2. It is a memory from your life that you remember very clearly and that still seems important to you, even when" +
          " you think about it.",
        3:
          "  3. This is a memory about an important and enduring theme, issue or conflict in your life. It is a memory that " +
          "helps explain who you are as an individual and could be the memory you would tell someone else if you wanted " +
          "them to understand you in depth.",
        4: "  4. It is a memory linked to other similar memories that share the same theme or concern.",
        5:
          "  5. It can be a memory that is positive or negative, or both, in the way it makes you feel. The only important " +
          "aspect is that it evokes strong feelings.",
        6:
          "  6. This is a memory that you have thought about many times. It should be familiar to you, like a picture you " +
          "studied or a song (happy or sad) you memorized.",
      },
      phrase2:
        "\nIn order to understand what a self-defining memory is, imagine that you have met someone you like and you are " +
        'going for a walk together. You both commit to letting the other person know who you really are, the "real you". ' +
        "Although what you may say might not give an accurate picture of yourself, be as honest as possible. During the " +
        "conversation, you describe several memories that you feel express how you came to be the person you are now. " +
        "These exact memories are what is meant by “self-defining memories”. Describe each memory in enough detail that " +
        "it can help your imaginary friend see and feel what you experienced.\n",
      phrase3:
        "Then, please give a brief title (caption) for each memory and answer a series of questions about what this" +
        " memory evokes in you. Recall, out loud, the first self-defining memory that comes to mind. ",
      phrase4:
        "Before starting the recordings, please make sure that you are in a quiet environment without disturbing noises" +
        " (ex. TV, open windows or doors) and that your microphone is working. When starting the recordings, please " +
        "accept the microphone activation request. ",
    },
    fp: {
      title: "Future projection",
      phrase1:
        "We are going to ask you to imagine, out loud, three future personal events that are particularly important to " +
        "you and that follow specific criteria:",
      list: {
        1: "  1. The temporal distance of the future event from the present must be at least one year.",
        2: "  2. The future event must be an important event that you clearly anticipate and can vividly represent.",
        3: "  3. It must help you and your significant others explain who you are as a person.",
        4:
          "  4. It must be an event related to an issue, conflict, or significant and enduring concern in your life and" +
          " linked to other events sharing the same theme.",
        5: "  5. It can be a positive or negative event, the only important aspect is that it evokes strong feelings.",
        6: "  6. It must be an event that you have thought about repeatedly.",
      },
    },
    cs: {
      title: "Cultural story",
      phrase1:
        "Imagine a quite ordinary infant (choose boy or girl according to your own gender). It cannot be a specific " +
        "infant that you know, but a “typical” infant in our culture with a quite ordinary life course ahead. Your task" +
        " is to write down the seven most important events that you imagine are highly likely to take place in this" +
        " “typical” infant’s life. Write the events in the same order as they come to your mind. Give each event a" +
        " short title that specifies its content.",
    },
    recordingInfo: {
      title: "Vocal tasks",
      text:
        "We are going to ask you to perform several vocal tasks that will enable us to better process your voice for " +
        "the previous parts of the experiment. Check that your microphone is switched on or authorize its use. Follow " +
        "the instructions given to you on the next pages.",
    },
  },
}

export default en
