import { useAudioRecorder } from "../hooks/useAudioRecorder"
import MicOffIcon from "@mui/icons-material/MicOff"
import { Box, Button, Stack, Typography } from "@mui/material"
import { RECORD_STATUS } from "../@types/recordingStatus"
import { useEffect, useState } from "react"
import usePatient from "../hooks/usePatient"
import { TaskRecording } from "../@types/taskRecording"
import { UpdateRecordingDTO, VoiceRecoded } from "../@types/patient"
import MicIcon from "@mui/icons-material/Mic"
import "react-h5-audio-player/lib/styles.css"
import AudioPlayer from "./AudioPlayer"
import useLocales from "../hooks/useLocales"
import { v4 as uuidv4 } from "uuid"
import useTimeUser from "../hooks/useTimeUser"
import { useParams } from "react-router-dom"
import projects from "../@projects"

type AudioRecorderProps = {
  task: TaskRecording
}

export const AudioRecorder = ({ task }: AudioRecorderProps) => {
  const { translate, currentLang } = useLocales()
  const { id } = useParams()
  const { startRecording, stopRecording, resumeRecording, status, audioResult, cleanRecording, timer, handleStartTimer, handleResetTimer } =
    useAudioRecorder()

  const { timeUser, handleStartTimeUser, handleResetTimeUser } = useTimeUser()
  const { patient, savePatientLocally, updateRecording } = usePatient()
  const [newTaskCompleted, setNewTaskCompleted] = useState(false)

  const [listenDisabled, setListenDisabled] = useState(false)
  const [recordDisabled, setRecordDisabled] = useState(false)

  const custom_green = "#229A16"
  const custom_red = "#ea3434"
  const custom_black = "#000000"

  const [greyFontText, setGreyFontText] = useState(true)
  const [colorRecordingButton, setColorRecordingButton] = useState(custom_green) //

  const project = projects.find((project) => project.projectID === id)

  const allVoiceRecordingTasks = project ? project.voiceRecordings.filter((task) => task.lang === currentLang.value) : []

  useEffect(() => {
    handleStartTimeUser()
  }, [])

  const recordButtonHandler = () => {
    setListenDisabled(true)
    if (status === RECORD_STATUS.IDLE) {
      startRecording()
      setColorRecordingButton(custom_black)
    } else {
      resumeRecording()
    }
    return
  }

  useEffect(() => {
    if (audioResult) {
      setNewTaskCompleted(true)
    }
  }, [audioResult])

  const saveAndGoNextStep = async () => {
    const nameAudioFile = uuidv4() + ".wav"

    const voiceRecorded: VoiceRecoded = {
      id: task.id,
      filename: nameAudioFile,
      time: `${timeUser}`,
    }

    const response = await fetch(audioResult)
      .then((b) => b.blob())
      .then((b) => new File([b], nameAudioFile, { type: "audio/wav" }))

    const updatedVoiceRecorded: VoiceRecoded[] = [...patient.voiceRecorded, voiceRecorded]
    const update: UpdateRecordingDTO = {
      project_id: patient.projectId,
      questionnaire_id: patient.questionnaireId,
      recordings: JSON.stringify(updatedVoiceRecorded),
      audioFile: response,
      recordings_completed: allVoiceRecordingTasks.length === updatedVoiceRecorded.length ? "true" : "false",
    }

    handleResetTimeUser()
    handleStartTimeUser()
    setNewTaskCompleted(false)
    cleanRecording()
    savePatientLocally({
      ...patient,
      voiceRecorded: updatedVoiceRecorded,
      recordingsCompleted: allVoiceRecordingTasks.length === updatedVoiceRecorded.length ? true : false,
    })
    setColorRecordingButton(custom_green)
    await updateRecording(update)
  }

  const stopButton = () => {
    setListenDisabled(false)
    stopRecording()
  }

  return (
    <Box sx={{ pt: 2, display: "flex", alignItems: "center", justifyContent: "center" }}>
      <Stack direction="column" spacing={1} sx={{ pt: 1, display: "flex", alignItems: "center", justifyContent: "center" }}>
        <Box>
          <Typography variant={"h4"}>{new Date(timer * 1000).toISOString().substring(11, 19)}</Typography>
        </Box>
        <Box>
          <Stack direction="row" spacing={audioResult ? 2 : 0}>
            {status === RECORD_STATUS.IDLE && (
              <Button
                variant="contained"
                sx={{ backgroundColor: colorRecordingButton, height: 100, width: 100, borderRadius: 90 }}
                onClick={recordButtonHandler}
                disabled={recordDisabled}
              >
                <Stack direction="column" justifyContent="center" alignContent="center" alignItems="center">
                  <MicIcon />
                  {audioResult ? (
                    <Typography>{translate("audioplayer.recordAgain")}</Typography>
                  ) : (
                    <Typography>{translate("audioplayer.record")}</Typography>
                  )}
                </Stack>
              </Button>
            )}
            {status === RECORD_STATUS.RECORDING && (
              <Button
                variant="contained"
                sx={{ backgroundColor: custom_red, height: 100, width: 100, borderRadius: 90 }}
                onClick={stopButton}
              >
                <Stack direction="column" justifyContent="center" alignContent="center" alignItems="center">
                  <MicOffIcon />
                  {translate("audioplayer.stop")}
                </Stack>
              </Button>
            )}
            <Box sx={{ alignItems: "center", justifyContent: "center" }}>
              {audioResult && (
                <AudioPlayer
                  audioSrc={audioResult}
                  handleStartTimer={handleStartTimer}
                  handleResetTimer={handleResetTimer}
                  listenDisabled={listenDisabled}
                  setRecordDisabled={setRecordDisabled}
                />
              )}
            </Box>
          </Stack>
        </Box>
        <Box sx={{ pt: 1 }}>
          <Button
            variant="contained"
            color="primary"
            sx={{ width: "90%", height: 70, fontSize: 28 }}
            disabled={!newTaskCompleted || listenDisabled || recordDisabled}
            onClick={saveAndGoNextStep}
          >
            {translate("project.goToNextStep")}
          </Button>
        </Box>
      </Stack>
    </Box>
  )
}

export default AudioRecorder
