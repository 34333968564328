import { GeneralAnswers } from "../../@types/patient"
import { Dispatch, SetStateAction } from "react"
import { MedicalQuestionSelect } from "../../@types/medicalQuestion"
import { Box, FormControl, Grid, MenuItem, Paper, Select, SelectChangeEvent, Typography } from "@mui/material"

type RenderMedicalQuestionSelectProps = {
  question: MedicalQuestionSelect
  answersForm: GeneralAnswers[]
  setAnswersForm: Dispatch<SetStateAction<GeneralAnswers[]>>
  currentQuestionnaireID: string
}

const RenderMedicalQuestionSelect = ({
  question,
  answersForm,
  setAnswersForm,
  currentQuestionnaireID,
}: RenderMedicalQuestionSelectProps) => {
  const handleAnswerChange = (event: SelectChangeEvent) => {
    setAnswersForm((answers: GeneralAnswers[]) => {
      const result: GeneralAnswers[] = answers.map((answer) => {
        if (answer.name === currentQuestionnaireID) {
          const updatedAnswers = answer.answers.map((answer) => {
            if (answer.id === question.id) {
              // replaceAll as in the mother tongue question we list twice the language => here we normalize these both questions
              return { ...answer, answer: event.target.value.replaceAll("$", "") }
            } else {
              return answer
            }
          })
          return { name: currentQuestionnaireID, answers: updatedAnswers, time: "" }
        } else {
          return answer
        }
      })
      return result
    })
  }

  return (
    <FormControl fullWidth sx={{ pt: 2, pb: 2, width: "100%" }} size="small">
      <Paper elevation={3}>
        <Grid container spacing={0} sx={{ width: "100%", pt: 1, pb: 1 }}>
          <Grid item xs={12} md={6} display="flex" justifyContent="center" alignContent="center" alignItems="flex-start">
            <Box width="80%" sx={{ pt: 1 }}>
              <Typography textAlign="center">{question.description}</Typography>
            </Box>
          </Grid>

          <Grid item xs={12} md={6} display="flex" justifyContent="center" alignContent="center" alignItems="center">
            <Box width="90%" sx={{ textJustify: "left" }}>
              <Select
                id={question.id}
                value={answersForm.flatMap((answers) => answers.answers.filter((answer) => answer.id === question.id))[0].answer}
                sx={{ width: "100%" }}
                onChange={handleAnswerChange}
              >
                {question.value.map((item) => (
                  <MenuItem key={item.value} value={item.value}>
                    {" "}
                    {item.label}{" "}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </FormControl>
  )
}

export default RenderMedicalQuestionSelect
