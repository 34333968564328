import { Categorical, MedicalQuestion } from "../../../../@types/medicalQuestion"

const category: Categorical[] = [
  {
    label: "yes",
    value: "yes",
  },
  {
    label: "no",
    value: "no",
  },
]

const respiratoryEN: MedicalQuestion[] = [
  {
    id: "asth",
    name: "Asthma",
    type: "categoricalShort",
    value: category,
    description: "Diagnosis of asthma ?",
    parent: "respiratory",
    order: 1,
    lang: "en",
    required: true,
    doctor: true,
  },
  {
    id: "copd",
    name: "Chronic obstructive pulmonary disease",
    type: "categoricalShort",
    value: category,
    description: "Diagnosis with COPD ?",
    parent: "respiratory",
    order: 2,
    lang: "en",
    required: true,
    doctor: true,
  },
  {
    id: "vems",
    name: "Forced expiratory volume in 1 second ",
    type: "writeNumeric",
    minValue: 500,
    maxValue: 10000,
    description: "VEF1 (mL) ?",
    parent: "respiratory",
    order: 3,
    lang: "en",
    required: true,
    doctor: true,
  },
  {
    id: "vems_z",
    name: "VEMS / Z-score",
    type: "writeNumeric",
    minValue: -20,
    maxValue: 20,
    description: "FEV1 (mL) / Z-score ?",
    parent: "respiratory",
    order: 4,
    lang: "en",
    required: false,
    doctor: true,
  },
  {
    id: "tiff",
    name: "Tiffeneau-Pinelli index",
    type: "writeNumeric",
    minValue: 0,
    maxValue: 100,
    description: "Tiffeneau-Pinelli index (%) ?",
    parent: "respiratory",
    order: 5,
    lang: "en",
    required: true,
    doctor: true,
  },
  {
    id: "lung_vol",
    name: "Lung volumes",
    type: "writeNumeric",
    minValue: 500,
    maxValue: 15000,
    description: "Total lung capacity (mL) ?",
    parent: "respiratory",
    order: 6,
    lang: "en",
    required: false,
    doctor: true,
  },
  {
    id: "lung_vol_res",
    name: "Lung volume residuel",
    type: "writeNumeric",
    minValue: 500,
    maxValue: 8000,
    description: "Residual lung volume (mL) ?",
    parent: "respiratory",
    order: 7,
    lang: "en",
    required: false,
    doctor: true,
  },
  {
    id: "mmrc",
    name: "Scale shortness of breath mMRC",
    type: "number",
    minValue: 0,
    maxValue: 4,
    description: "mMRC Dyspnea Scale ?",
    parent: "respiratory",
    order: 8,
    lang: "en",
    required: false,
    doctor: true,
  },
  {
    id: "peak_flow",
    name: "Peak expiratory flow (L/min)",
    type: "writeNumeric",
    minValue: 0,
    maxValue: 1000,
    description: "Peak expiratory flow (L/min)",
    parent: "respiratory",
    order: 9,
    lang: "en",
    required: false,
    doctor: true,
  },
  {
    id: "raw",
    name: "Airway resistance RAW (%)",
    type: "writeNumeric",
    minValue: 0,
    maxValue: 1000,
    description: "Airway resistance RAW (%)",
    parent: "respiratory",
    order: 10,
    lang: "en",
    required: false,
    doctor: true,
  },
  {
    id: "exacer",
    name: "Current exacerbation",
    type: "categoricalShort",
    value: category,
    description: "Current exacerbation ?",
    parent: "respiratory",
    order: 11,
    lang: "en",
    required: false,
    doctor: true,
  },
  {
    id: "n_exacer",
    name: "Number exacerbation over 12 last months",
    type: "number",
    minValue: 0,
    maxValue: 20,
    description: "Number of exacerbations in the last 12 months?",
    parent: "respiratory",
    order: 12,
    lang: "en",
    required: false,
    doctor: true,
  },
  {
    id: "date_exacer_m",
    name: "Date last exacerbation month",
    type: "select",
    value: [
      { label: "January", value: "january" },
      { label: "February", value: "february" },
      { label: "March", value: "march" },
      { label: "April", value: "april" },
      { label: "Mai", value: "mai" },
      { label: "June", value: "june" },
      { label: "July", value: "juli" },
      { label: "August", value: "august" },
      { label: "September", value: "september" },
      { label: "October", value: "october" },
      { label: "November", value: "november" },
      { label: "December", value: "december" },
      { label: "Missing information", value: "missing-information" },
    ],
    description: "Date of the last exacerbation (month) ?",
    parent: "respiratory",
    order: 13,
    lang: "en",
    required: false,
    doctor: true,
  },
  {
    id: "date_exacer_y",
    name: "Date last exacerbation year",
    type: "number",
    minValue: 2000,
    maxValue: 2023,
    description: "Date of the last exacerbation (year) ?",
    parent: "respiratory",
    order: 14,
    lang: "en",
    required: false,
    doctor: true,
  },
  {
    id: "n_hosp_exac",
    name: "Number hospitalisations due to exacerbation over 12 last months",
    type: "number",
    minValue: 0,
    maxValue: 20,
    description: "Number of hospitalizations for exacerbations in the last 12 months?",
    parent: "respiratory",
    order: 15,
    lang: "en",
    required: false,
    doctor: true,
  },
  {
    id: "date_hosp_m",
    name: "Date last hospitalisation due to une exacerbation (month)",
    type: "select",
    value: [
      { label: "January", value: "january" },
      { label: "February", value: "february" },
      { label: "March", value: "march" },
      { label: "April", value: "april" },
      { label: "Mai", value: "mai" },
      { label: "June", value: "june" },
      { label: "July", value: "juli" },
      { label: "August", value: "august" },
      { label: "September", value: "september" },
      { label: "October", value: "october" },
      { label: "November", value: "november" },
      { label: "December", value: "december" },
      { label: "Missing information", value: "missing-information" },
    ],
    description: "Date of the last hospitalization due to exacerbation (month) ?",
    parent: "respiratory",
    order: 16,
    lang: "en",
    required: false,
    doctor: true,
  },
  {
    id: "date_hosp_y",
    name: "Date last hospitalisation due to une exacerbation (year)",
    type: "number",
    minValue: 2000,
    maxValue: 2023,
    description: "Date of the last hospitalization due to exacerbation (year) ?",
    parent: "respiratory",
    order: 17,
    lang: "en",
    required: false,
    doctor: true,
  },
]

export default respiratoryEN
