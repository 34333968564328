import {
  isMedicalQuestionCategoricalLong,
  isMedicalQuestionCategoricalShort,
  isMedicalQuestionHeaderText,
  isMedicalQuestionFreeText,
  isMedicalQuestionIntro,
  isMedicalQuestionMulti,
  isMedicalQuestionNumeric,
  isMedicalQuestionSelect,
  isMedicalQuestionWriteNumeric,
  MedicalQuestion,
} from "../../@types/medicalQuestion"
import { GeneralAnswers } from "../../@types/patient"
import { Dispatch, SetStateAction } from "react"
import { Box } from "@mui/material"
import RenderMedicalQuestionCategoricalShort from "./RenderMedicalQuestionCategoricalShort"
import RenderMedicalQuestionCategoricalLong from "./RenderMedicalQuestionCategoricalLong"
import RenderMedicalQuestionMulti from "./RenderMedicalQuestionMulti"
import RenderMedicalQuestionSelect from "./RenderMedicalQuestionSelect"
import RenderMedicalQuestionNumeric from "./RenderMedicalQuestionNumeric"
import RenderMedicalQuestionIntro from "./RenderMedicalQuestionIntro"
import RenderMedicalQuestionHeaderText from "./RenderMedicalQuestionHeaderText"
import RenderMedicalQuestionWriteNumeric from "./RenderMedicalQuestionWriteNumeric"
import RenderMedicalQuestionFreeText from "./RenderMedicalQuestionFreeText"

type MedicalQuestionProps = {
  question: MedicalQuestion
  answersForm: GeneralAnswers[]
  setAnswersForm: Dispatch<SetStateAction<GeneralAnswers[]>>
  currentQuestionnaireID: string
}

const RenderMedicalQuestion = ({ question, answersForm, setAnswersForm, currentQuestionnaireID }: MedicalQuestionProps) => {
  const dependsOn = question.dependsOn
  const otherValue =
    currentQuestionnaireID && answersForm.filter((q) => q.name === currentQuestionnaireID)[0].answers.find((q) => q.id === dependsOn?.id)

  if (otherValue && dependsOn) {
    // check if any answer matches the criteria in dependsOn
    const otherValueSplit = otherValue.answer
      .toString()
      .split(";") // do this for answers of type "multi"
      .filter((ff) => ff.length > 0)
      .filter((value) => dependsOn.values.includes(value))

    if (otherValueSplit.length == 0) {
      return <></>
    }
  }

  return (
    <Box sx={{ display: "flex" }}>
      {isMedicalQuestionCategoricalShort(question) &&
        RenderMedicalQuestionCategoricalShort({ question, answersForm, setAnswersForm, currentQuestionnaireID })}

      {isMedicalQuestionCategoricalLong(question) &&
        RenderMedicalQuestionCategoricalLong({ question, answersForm, setAnswersForm, currentQuestionnaireID })}

      {isMedicalQuestionMulti(question) && RenderMedicalQuestionMulti({ question, answersForm, setAnswersForm, currentQuestionnaireID })}

      {isMedicalQuestionSelect(question) && RenderMedicalQuestionSelect({ question, answersForm, setAnswersForm, currentQuestionnaireID })}

      {isMedicalQuestionNumeric(question) &&
        RenderMedicalQuestionNumeric({ question, answersForm, setAnswersForm, currentQuestionnaireID })}

      {isMedicalQuestionWriteNumeric(question) &&
        RenderMedicalQuestionWriteNumeric({ question, answersForm, setAnswersForm, currentQuestionnaireID })}

      {isMedicalQuestionIntro(question) && RenderMedicalQuestionIntro({ question })}

      {isMedicalQuestionHeaderText(question) && RenderMedicalQuestionHeaderText({ question })}
      {isMedicalQuestionFreeText(question) &&
        RenderMedicalQuestionFreeText({ question, answersForm, setAnswersForm, currentQuestionnaireID })}
    </Box>
  )
}

export default RenderMedicalQuestion
