import { createContext, ReactNode } from "react"
import {
  EmailDTO,
  NewQuestionnaireDTO,
  Patient,
  PatientContextType,
  QuestionnaireCreatedDTO,
  QuestionnaireUpdatedDTO,
  UpdateRecordingDTO,
} from "../@types/patient"
import useLocalStorage from "../hooks/useLocalStorage"
import { post } from "../fetchers/fetchers"
import axios from "axios"
import {
  deviceType,
  isMobileOnly,
  isTablet,
  isDesktop,
  isAndroid,
  isWinPhone,
  isIOS,
  isChrome,
  isFirefox,
  isSafari,
  isOpera,
  isIE,
  osVersion,
  fullBrowserVersion,
  browserVersion,
  browserName,
  mobileVendor,
  mobileModel,
  engineName,
  engineVersion,
  isWindows,
  isMacOs,
  osName,
} from "react-device-detect"

const PatientContext = createContext<PatientContextType | null>(null)

type PatientProviderProps = {
  children: ReactNode
}

const PatientProvider = ({ children }: PatientProviderProps) => {
  const initialValue: Patient = {
    questionnaireId: "",
    patientId: "",
    projectId: "",
    deviceInfo: {
      isMobileOnly: isMobileOnly,
      isTablet: isTablet,
      isDesktop: isDesktop,
      isAndroid: isAndroid,
      isWinPhone: isWinPhone,
      isIOS: isIOS,
      isChrome: isChrome,
      isFirefox: isFirefox,
      isSafari: isSafari,
      isOpera: isOpera,
      isIE: isIE,
      osVersion: osVersion,
      osName: osName,
      fullBrowserVersion: fullBrowserVersion,
      browserVersion: browserVersion,
      browserName: browserName,
      mobileVendor: mobileVendor,
      mobileModel: mobileModel,
      engineName: engineName,
      engineVersion: engineVersion,
      deviceType: deviceType,
      isWindows: isWindows,
      isMacOs: isMacOs,
    },
    voiceRecorded: [],
    questionsAnswered: [],
    currentQuestionnairesCompleted: [],
    diseaseQuestionsAnswered: [],
    doctorCompleted: false,
    questionnairesCompleted: false,
    recordingsCompleted: false,
  }

  const [patient, setPatient] = useLocalStorage("patient", initialValue)

  const savePatientLocally = (patient: Patient) => {
    setPatient(patient)
  }

  const createQuestionnaire = async (params: NewQuestionnaireDTO) => {
    await post<NewQuestionnaireDTO, QuestionnaireCreatedDTO>(params, process.env.REACT_APP_BACKEND_CREATE)
      .then((response) => {
        setPatient({
          questionnaireId: response.questionnaire_id,
          patientId: response.patient_id,
          projectId: response.project_id,
          deviceInfo: response.device_info, //{ deviceType: deviceType },
          voiceRecorded: [],
          questionsAnswered: [],
          currentQuestionnairesCompleted: [],
          diseaseQuestionsAnswered: [],
          doctorCompleted: false,
          questionnairesCompleted: false,
          recordingsCompleted: false,
        })
      })
      .catch((err) => {
        console.log(`createQuestionnaire error: ${JSON.stringify(err)}`)
      })
  }

  const updateRecording = async (params: UpdateRecordingDTO) => {
    const formData = new FormData()
    formData.append("file", params.audioFile)
    formData.append("filename", params.audioFile.name)
    formData.append("project_id", params.project_id)
    formData.append("questionnaire_id", params.questionnaire_id)
    formData.append("recordings", params.recordings)
    formData.append("recordings_completed", params.recordings_completed)
    return await axios
      .post<string>(process.env.REACT_APP_BACKEND_URI + process.env.REACT_APP_BACKEND_UPDATE_RECORDING, formData)
      .then((response) => {})
      .catch((err) => {
        console.log(`updateRecording error: ${err}`)
      })
  }

  const updateQuestionnaire = async (params: QuestionnaireUpdatedDTO) => {
    return post<QuestionnaireUpdatedDTO, QuestionnaireCreatedDTO>(params, process.env.REACT_APP_BACKEND_UPDATE_ANSWERS)
      .then((response) => {})
      .catch((err) => {
        console.log(`updateQuestionnaire error: ${err}`)
      })
  }

  const updateDiseaseQuestionnaire = async (params: QuestionnaireUpdatedDTO) => {
    return post<QuestionnaireUpdatedDTO, QuestionnaireCreatedDTO>(params, process.env.REACT_APP_BACKEND_UPDATE_ANSWERS_DISEASE)
      .then((response) => {})
      .catch((err) => {
        console.log(`updateDiseaseQuestionnaire error: ${err}`)
      })
  }

  const updateQuestionnaireDoctor = async (params: QuestionnaireUpdatedDTO) => {
    return post<QuestionnaireUpdatedDTO, QuestionnaireCreatedDTO>(params, process.env.REACT_APP_BACKEND_UPDATE_ANSWERS_DOCTOR)
      .then((response) => {})
      .catch((err) => {
        console.log(`updateQuestionnaireDoctor error: ${err}`)
      })
  }
  const cleanPatient = async () => {
    setPatient(initialValue)
    localStorage.removeItem("patient")
  }

  const updateEmail = async (params: EmailDTO) => {
    return post<EmailDTO, QuestionnaireCreatedDTO>(params, process.env.REACT_APP_BACKEND_UPDATE_EMAIL)
      .then((response) => {})
      .catch((err) => {
        console.log(`updateEmail error: ${err}`)
        console.log(process.env.REACT_APP_BACKEND_UPDATE_EMAIL)
      })
  }

  const verifyEmailExists = async (params: EmailDTO) => {
    return post<EmailDTO, boolean>(params, process.env.REACT_APP_BACKEND_VERIFY_EMAIL_EXISTS)
      .then((response) => {
        return true
      })
      .catch((err) => {
        console.log(`verifyEmailExists error: ${err}`)
        return false
      })
  }

  return (
    <PatientContext.Provider
      value={{
        patient,
        savePatientLocally,
        createQuestionnaire,
        updateRecording,
        updateQuestionnaire,
        cleanPatient,
        updateQuestionnaireDoctor,
        updateDiseaseQuestionnaire,
        updateEmail,
        verifyEmailExists,
      }}
    >
      {children}
    </PatientContext.Provider>
  )
}

export { PatientContext, PatientProvider }
