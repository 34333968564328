import { MedicalQuestion } from "../../../../@types/medicalQuestion"

const actFR: MedicalQuestion[] = [
  {
    id: "act_1",
    name: "Asthma control test",
    type: "categoricalShort",
    value: [
      {
        label: "Tout le temps",
        value: "all-of-the-time",
      },
      {
        label: "La plupart du temps",
        value: "most-of-the-time",
      },
      {
        label: "Quelques fois",
        value: "some-of-the-time",
      },
      {
        label: "Rarement",
        value: "a-little-of-the-time",
      },
      {
        label: "Jamais",
        value: "none-of-the-time",
      },
    ],
    description:
      "Au cours des 4 dernières semaines, votre asthme vous a-t-il empêché(e) de pratiquer vos activités au travail,\n" +
      "à l’école/université ou chez vous ?",
    parent: "act",
    order: 1,
    lang: "fr",
    required: true,
  },
  {
    id: "act_2",
    name: "Asthma control test",
    type: "categoricalShort",
    value: [
      {
        label: "Plus d'1 fois par jour",
        value: "more-than-once-per-day",
      },
      {
        label: "1 fois par jour",
        value: "once-per-day",
      },
      {
        label: "3 à 6 fois par semaine",
        value: "three-to-six-per-week",
      },
      {
        label: "1 ou 2 fois par semaine",
        value: "once-or-twice-per-week",
      },
      {
        label: "Jamais",
        value: "never",
      },
    ],
    description: "Au cours des 4 dernières semaines, avez-vous été essoufflé(e) ?",
    parent: "act",
    order: 2,
    lang: "fr",
    required: true,
  },
  {
    id: "act_3",
    name: "Asthma control test",
    type: "categoricalShort",
    value: [
      {
        label: "4 nuits ou plus par semaine",
        value: "four-nights-or-more-per-week",
      },
      {
        label: "2 à 3 nuits par semaine",
        value: "two-to-three-nights-per-week",
      },
      {
        label: "1 nuit par semaine",
        value: "one-night-per-week",
      },
      {
        label: "Juste 1 ou 2 fois",
        value: "one-or-two",
      },
      {
        label: "Jamais",
        value: "never",
      },
    ],
    description:
      "Au cours des 4 dernières semaines, les symptômes de l’asthme (sifflements dans la poitrine, toux,\n" +
      "essoufflement, oppression ou douleur dans la poitrine) vous ont-ils réveillé(e) la nuit ou plus tôt que\n" +
      "d’habitude le matin ?",
    parent: "act",
    order: 3,
    lang: "fr",
    required: true,
  },
  {
    id: "act_4",
    name: "Asthma control test",
    type: "categoricalShort",
    value: [
      {
        label: "3 fois ou plus par jour",
        value: "three-times-or-more-per-day",
      },
      {
        label: "1 ou 2 fois par jour",
        value: "once-or-twice-per-day",
      },
      {
        label: "2 ou 3 fois par semaine",
        value: "two-or-three-times-per-week",
      },
      {
        label: "1 fois ou moins par semaine",
        value: "one-or-less-per-week",
      },
      {
        label: "Jamais",
        value: "never",
      },
    ],
    description:
      "Au cours des 4 dernières semaines, avez-vous utilisé votre inhalateur de secours ou pris un traitement" +
      " par nébulisation (par exemple salbutamol, terbutaline) ?",
    parent: "act",
    order: 4,
    lang: "fr",
    required: true,
  },
  {
    id: "act_5",
    name: "Asthma control test",
    type: "categoricalShort",
    value: [
      {
        label: "Pas maîtrisé du tout",
        value: "not-controlled-at-all",
      },
      {
        label: "Très peu maîtrisé",
        value: "poorly-controlled",
      },
      {
        label: "Un peu maîtrisé",
        value: "somewhat-controlled",
      },
      {
        label: "Bien maîtrisé",
        value: "well-controlled",
      },
      {
        label: "Totalement maîtrisé",
        value: "completely-controlled",
      },
    ],
    description: "Comment évalueriez-vous votre maîtrise de l’asthme au cours des 4 dernières semaines ?",
    parent: "act",
    order: 5,
    lang: "fr",
    required: true,
  },
]

export default actFR
