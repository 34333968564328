import { MedicalQuestion } from "../../../../@types/medicalQuestion"

const actDE: MedicalQuestion[] = [
  {
    id: "act_1",
    name: "Asthma control test",
    type: "categoricalShort",
    value: [
      {
        label: "Immer",
        value: "all-of-the-time",
      },
      {
        label: "Meistens",
        value: "most-of-the-time",
      },
      {
        label: "Manchmal",
        value: "some-of-the-time",
      },
      {
        label: "Selten",
        value: "a-little-of-the-time",
      },
      {
        label: "Nie",
        value: "none-of-the-time",
      },
    ],
    description:
      "Wie oft hat Ihr Asthma Sie in den letzten 4 Wochen daran gehindert, bei der Arbeit, in der Schule/im " +
      "Studium oder zu Hause so viel zu erledigen wie sonst ?",
    parent: "act",
    order: 1,
    lang: "de",
    required: true,
  },
  {
    id: "act_2",
    name: "Asthma control test",
    type: "categoricalShort",
    value: [
      {
        label: "Mehr als einmal am Tag",
        value: "more-than-once-per-day",
      },
      {
        label: "Einmal am Tag",
        value: "once-per-day",
      },
      {
        label: "3 bis 6 Mal pro Woche",
        value: "three-to-six-per-week",
      },
      {
        label: "Ein- oder zweimal pro Woche",
        value: "once-or-twice-per-week",
      },
      {
        label: "Überhaupt nicht",
        value: "never",
      },
    ],
    description: "Wie oft haben Sie in den letzten 4 Wochen unter Kurzatmigkeit gelitten ?",
    parent: "act",
    order: 2,
    lang: "de",
    required: true,
  },
  {
    id: "act_3",
    name: "Asthma control test",
    type: "categoricalShort",
    value: [
      {
        label: "4 oder mehr Nächte pro Woche",
        value: "four-nights-or-more-per-week",
      },
      {
        label: "2 oder 3 Nächte pro Woche",
        value: "two-to-three-nights-per-week",
      },
      {
        label: "Einmal pro Woche",
        value: "one-night-per-week",
      },
      {
        label: "Ein- oder zweimal",
        value: "one-or-two",
      },
      {
        label: "Überhaupt nicht",
        value: "never",
      },
    ],
    description:
      "Wie oft sind Sie in den letzten 4 Wochen wegen Ihrer Asthmabeschwerden (pfeifendes Atemgeräusch, " +
      "Husten, Kurzatmigkeit, Engegefühl oder Schmerzen in der Brust) nachts wach geworden oder morgens früher als " +
      "gewöhnlich aufgewacht ?",
    parent: "act",
    order: 3,
    lang: "de",
    required: true,
  },
  {
    id: "act_4",
    name: "Asthma control test",
    type: "categoricalShort",
    value: [
      {
        label: "3 Mal oder öfter am Tag",
        value: "three-times-or-more-per-day",
      },
      {
        label: "1 oder 2 Mal am Tag",
        value: "once-or-twice-per-day",
      },
      {
        label: "2 oder 3 Mal pro Woche",
        value: "two-or-three-times-per-week",
      },
      {
        label: "Einmal pro Woche oder weniger",
        value: "one-or-less-per-week",
      },
      {
        label: "Überhaupt nicht",
        value: "never",
      },
    ],
    description:
      "Wie oft haben Sie in den letzten 4 Wochen Ihr Notfallmedikament zur Inhalation (Spray, Vernebler, wie" +
      " z.B. Salbutamol) eingesetzt ?",
    parent: "act",
    order: 4,
    lang: "de",
    required: true,
  },
  {
    id: "act_5",
    name: "Asthma control test",
    type: "categoricalShort",
    value: [
      {
        label: "Überhaupt nicht",
        value: "not-controlled-at-all",
      },
      {
        label: "Schlecht",
        value: "poorly-controlled",
      },
      {
        label: "Etwas",
        value: "somewhat-controlled",
      },
      {
        label: "Gut",
        value: "well-controlled",
      },
      {
        label: "Völlig",
        value: "completely-controlled",
      },
    ],
    description: "Wie gut hatten Sie in den letzten 4 Wochen Ihr Asthma unter Kontrolle ?",
    parent: "act",
    order: 5,
    lang: "de",
    required: true,
  },
]

export default actDE
