import consents from "./consent"
import tasksRecording from "./tasks"
import { Project } from "../../@types/project"
import respiratory from "./questions/respiratory"
import cofounding from "./questions/cofounding"
import ACT from "./questions/act"
import CAT from "./questions/cat"
import fss from "./questions/fss"
import PHQ from "./questions/phq"
import GAD from "./questions/gad"
import VQ from "./questions/vq"
import general from "./questions/general"

const project: Project = {
  projectID: "4e7220cb-485a-4268-8ce9-3e7ff413d7e0",
  projectName: "Speakuity R1",
  projectLocation: "Square de la mutualite",
  projectCreated: "08/01/2023",
  projectType: "oneshot",
  defaultLang: "fr",
  projectLang: ["en", "fr", "de"],
  voiceRecordings: tasksRecording,
  questionnaires: [
    { questionnaire: general, optional: false },
    { questionnaire: PHQ, optional: false },
    { questionnaire: GAD, optional: false },
    { questionnaire: fss, optional: false },
    { questionnaire: VQ, optional: false },
    { questionnaire: cofounding, optional: false },
    { questionnaire: ACT, optional: true, dependsOnQuestion: { questionnaireId: "cofounding", question_id: "asth", value: "yes" } },
    { questionnaire: CAT, optional: true, dependsOnQuestion: { questionnaireId: "cofounding", question_id: "copd", value: "yes" } },
  ],
  diseaseQuestions: [{ questionnaire: respiratory, optional: false }],
  consents: consents,
  isPatientAnsweringDiseaseQuestions: false,
  isStartWithQuestionnaires: true,
  isAskPhoneNumber: false,
  showPatientIdFinalPage: true,
  versionData: "1",
  transparenceID: "f32ke1",
}

export default project
