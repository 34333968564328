import selfDefiningMemoryEN from "./self-defining-memory_en"
import selfDefiningMemoryFR from "./self-defining-memory_fr"
import { MixedQuestionRecording } from "../../../../@types/mixedQuestionRecording"

//const selfDefiningMemory: MedicalQuestion[] = [...selfDefiningMemoryEN, ...selfDefiningMemoryFR]
const selfDefiningMemory: MixedQuestionRecording = {
  questionnaires: [...selfDefiningMemoryEN.questionnaires, ...selfDefiningMemoryFR.questionnaires],
  recordings: [...selfDefiningMemoryEN.recordings, ...selfDefiningMemoryFR.recordings],
}

export default selfDefiningMemory
