import consents from "./consent"
import tasksRecording from "./tasks"
import { Project } from "../../@types/project"
import general from "./questions/general"
import selfDefiningMemory from "./questions/sdm"
import futureProjection from "./questions/fp"
import culturalStory from "./questions/cs"
import PHQ from "./questions/phq"
import GAD from "./questions/gad"
import fss from "./questions/fss"
import pcl from "./questions/pcl"

const project: Project = {
  projectID: "47ba2106-c26a-45f3-8df2-70a5a84c0907",
  projectName: "ENSA",
  projectLocation: "MH6",
  projectCreated: "15/11/2023",
  projectType: "longitudinal",
  defaultLang: "fr",
  projectLang: ["fr", "en"],
  voiceRecordings: tasksRecording,
  questionnaires: [
    { questionnaire: general, optional: false },
    { questionnaire: PHQ, optional: false },
    { questionnaire: GAD, optional: false },
    { questionnaire: fss, optional: false },
    { questionnaire: pcl, optional: false },
  ],
  selfDefiningMemory: [{ task: selfDefiningMemory, type: "sdm", repetitions: 3 }],
  futureProjection: [{ task: futureProjection, type: "fp", repetitions: 3 }],
  culturalStory: [{ task: culturalStory, type: "cs", repetitions: 7 }],
  diseaseQuestions: [],
  consents: consents,
  isPatientAnsweringDiseaseQuestions: false,
  isStartWithQuestionnaires: true,
  isAskPhoneNumber: false,
  showPatientIdFinalPage: true,
  versionData: "1",
  transparenceID: "93k34u", //"h4h89m",
}

export default project
