import { Categorical, MedicalQuestion } from "../../../../@types/medicalQuestion"

const category: Categorical[] = [
  {
    label: "Pas du tout",
    value: "not-at-all",
  },
  {
    label: "Un peu",
    value: "a-little-bit",
  },
  {
    label: "Modérément",
    value: "moderately",
  },
  {
    label: "Beaucoup",
    value: "quite-a-bit",
  },
  {
    label: "Extrêmement",
    value: "extremely",
  },
]

const pclFR: MedicalQuestion[] = [
  {
    id: "pcl_intro",
    name: "intro",
    value:
      "Voici une liste de problèmes que les gens éprouvent parfois suite à une expérience vraiment stressante. " +
      "Veuillez lire chaque énoncé attentivement et cocher la case pour indiquer dans quelle mesure ce problème vous" +
      " a affecté dans le dernier mois.",
    type: "intro",
    lang: "fr",
    parent: "pcl",
    required: false,
  },
  {
    id: "pcl_intro_2",
    name: "intro",
    description: "Dans le dernier mois, dans quelle mesure avez-vous été affecté par :",
    type: "headerText",
    headerStyle: "subtitle1",
    lang: "fr",
    order: 1,
    parent: "pcl",
    required: false,
  },
  {
    id: "pcl_1",
    name: "PCL-5 ",
    type: "categoricalShort",
    value: category,
    description: "Des souvenirs répétés, pénibles et involontaires de l’expérience stressante ?",
    parent: "pcl",
    order: 2,
    lang: "fr",
    required: true,
  },
  {
    id: "pcl_2",
    name: "PCL-5 ",
    type: "categoricalShort",
    value: category,
    description: "Des rêves répétés et pénibles de l’expérience stressante ?",
    parent: "pcl",
    order: 3,
    lang: "fr",
    required: true,
  },
  {
    id: "pcl_3",
    name: "PCL-5 ",
    type: "categoricalShort",
    value: category,
    description: "Se sentir ou agir soudainement comme si vous viviez à nouveau l’expérience stressante ?",
    parent: "pcl",
    order: 4,
    lang: "fr",
    required: true,
  },
  {
    id: "pcl_4",
    name: "PCL-5 ",
    type: "categoricalShort",
    value: category,
    description: "Se sentir mal quand quelque chose vous rappelle l’événement ?",
    parent: "pcl",
    order: 5,
    lang: "fr",
    required: true,
  },
  {
    id: "pcl_5",
    name: "PCL-5 ",
    type: "categoricalShort",
    value: category,
    description:
      "Avoir de fortes réactions physiques lorsque quelque chose vous rappelle l’événement (accélération cardiaque," +
      " difficulté respiratoire, sudation) ?",
    parent: "pcl",
    order: 6,
    lang: "fr",
    required: true,
  },
  {
    id: "pcl_6",
    name: "PCL-5 ",
    type: "categoricalShort",
    value: category,
    description: "Essayer d’éviter les souvenirs, pensées, et sentiments liés à l’événement ?",
    parent: "pcl",
    order: 7,
    lang: "fr",
    required: true,
  },
  {
    id: "pcl_7",
    name: "PCL-5 ",
    type: "categoricalShort",
    value: category,
    description:
      "Essayer d’éviter les personnes et les choses qui vous rappellent l’expérience stressante (lieux, personnes," +
      " activités, objets) ?",
    parent: "pcl",
    order: 8,
    lang: "fr",
    required: true,
  },
  {
    id: "pcl_8",
    name: "PCL-5 ",
    type: "categoricalShort",
    value: category,
    description: "Des difficultés à vous rappeler des parties importantes de l’événement ?",
    parent: "pcl",
    order: 9,
    lang: "fr",
    required: true,
  },
  {
    id: "pcl_9",
    name: "PCL-5 ",
    type: "categoricalShort",
    value: category,
    description:
      "Des croyances négatives sur vous-même, les autres, le monde (des croyances\n" +
      "comme : je suis mauvais, j’ai quelque chose qui cloche, je ne peux avoir\n" +
      "confiance en personne, le monde est dangereux) ?",
    parent: "pcl",
    order: 10,
    lang: "fr",
    required: true,
  },
  {
    id: "pcl_10",
    name: "PCL-5 ",
    type: "categoricalShort",
    value: category,
    description: "Vous blâmer ou blâmer quelqu’un d’autre pour l’événement ou ce qui s’est\n" + "produit ensuite ?",
    parent: "pcl",
    order: 11,
    lang: "fr",
    required: true,
  },
  {
    id: "pcl_11",
    name: "PCL-5 ",
    type: "categoricalShort",
    value: category,
    description: "Avoir des sentiments négatifs intenses tels que peur, horreur, colère,\n" + "culpabilité, ou honte ?",
    parent: "pcl",
    order: 12,
    lang: "fr",
    required: true,
  },
  {
    id: "pcl_12",
    name: "PCL-5 ",
    type: "categoricalShort",
    value: category,
    description: "Perdre de l’intérêt pour des activités que vous aimiez auparavant ?",
    parent: "pcl",
    order: 13,
    lang: "fr",
    required: true,
  },
  {
    id: "pcl_13",
    name: "PCL-5 ",
    type: "categoricalShort",
    value: category,
    description: "Vous sentir distant ou coupé des autres ?",
    parent: "pcl",
    order: 14,
    lang: "fr",
    required: true,
  },
  {
    id: "pcl_14",
    name: "PCL-5 ",
    type: "categoricalShort",
    value: category,
    description:
      "Avoir du mal à éprouver des sentiments positifs (par exemple être incapable de ressentir de la joie ou de" +
      " l’amour envers vos proches) ?",
    parent: "pcl",
    order: 15,
    lang: "fr",
    required: true,
  },
  {
    id: "pcl_15",
    name: "PCL-5 ",
    type: "categoricalShort",
    value: category,
    description: "Comportement irritable, explosions de colère, ou agir agressivement ?",
    parent: "pcl",
    order: 16,
    lang: "fr",
    required: true,
  },
  {
    id: "pcl_16",
    name: "PCL-5 ",
    type: "categoricalShort",
    value: category,
    description: "Prendre des risques inconsidérés ou encore avoir des conduites qui pourraient vous mettre en danger ?",
    parent: "pcl",
    order: 17,
    lang: "fr",
    required: true,
  },
  {
    id: "pcl_17",
    name: "PCL-5 ",
    type: "categoricalShort",
    value: category,
    description: "Être en état de « super-alerte », hyper vigilant ou sur vos gardes ?",
    parent: "pcl",
    order: 18,
    lang: "fr",
    required: true,
  },
  {
    id: "pcl_18",
    name: "PCL-5 ",
    type: "categoricalShort",
    value: category,
    description: "Sursauter facilement ?",
    parent: "pcl",
    order: 19,
    lang: "fr",
    required: true,
  },
  {
    id: "pcl_19",
    name: "PCL-5 ",
    type: "categoricalShort",
    value: category,
    description: "Avoir du mal à vous concentrer ?",
    parent: "pcl",
    order: 20,
    lang: "fr",
    required: true,
  },
  {
    id: "pcl_20",
    name: "PCL-5 ",
    type: "categoricalShort",
    value: category,
    description: "Avoir du mal à trouver le sommeil ou à rester endormi ?",
    parent: "pcl",
    order: 21,
    lang: "fr",
    required: true,
  },
]

export default pclFR
