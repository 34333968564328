import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react"
import { Box, Button, Stack } from "@mui/material"
import PlayArrowIcon from "@mui/icons-material/PlayArrow"
import Pause from "@mui/icons-material/Pause"
import useLocales from "../hooks/useLocales"
type AudioPlayerProps = {
  audioSrc: string
  handleStartTimer: () => void
  handleResetTimer: () => void
  listenDisabled: boolean
  setRecordDisabled: Dispatch<SetStateAction<boolean>>
}

const AudioPlayer = ({ audioSrc, handleStartTimer, handleResetTimer, listenDisabled, setRecordDisabled }: AudioPlayerProps) => {
  const [isPlaying, setIsPlaying] = useState(false)
  const audioRef = useRef(new Audio(audioSrc))
  const { translate } = useLocales()
  const custom_black = "#000000"

  const intervalRef = useRef<NodeJS.Timer>(
    setInterval(() => {
      if (audioRef.current.ended) {
        setIsPlaying(false)
        setRecordDisabled(false)
      }
    }, 1000)
  )

  const listenButton = () => {
    setRecordDisabled(true)
    setIsPlaying(true)
  }

  const pauseButton = () => {
    setRecordDisabled(false)
    setIsPlaying(false)
  }

  useEffect(() => {
    if (isPlaying) {
      audioRef.current.play()
      handleStartTimer()
    } else {
      audioRef.current.pause()
      handleResetTimer()
    }
  }, [isPlaying])

  useEffect(() => {
    audioRef.current = new Audio(audioSrc)
    audioRef.current.pause()
    clearInterval(intervalRef.current)
  }, [audioSrc])

  return (
    <Box>
      {isPlaying ? (
        <Button variant="contained" onClick={pauseButton} sx={{ height: 100, width: 100, borderRadius: 90, backgroundColor: custom_black }}>
          <Stack direction="column" justifyContent="center" alignContent="center" alignItems="center">
            <Pause />
            {translate("audioplayer.pause")}
          </Stack>
        </Button>
      ) : (
        <Button
          variant="contained"
          onClick={listenButton}
          sx={{ height: 100, width: 100, borderRadius: 90, backgroundColor: custom_black }}
          disabled={listenDisabled}
        >
          <Stack direction="column" justifyContent="center" alignContent="center" alignItems="center">
            <PlayArrowIcon />
            {translate("audioplayer.listen")}
          </Stack>
        </Button>
      )}
    </Box>
  )
}

export default AudioPlayer
