import { MixedQuestionRecording } from "../../../../@types/mixedQuestionRecording"

const futureProjectionEN: MixedQuestionRecording = {
  questionnaires: [
    {
      id: "fp_q2",
      name: "Title of event",
      type: "freeText",
      description: "Title of event",
      parent: "fp",
      order: 1,
      lang: "en",
      required: true,
    },
    {
      id: "fp_q3",
      name: "Age",
      type: "number",
      minValue: 1,
      maxValue: 100,
      description: "My age at the time of the event",
      parent: "fp",
      order: 2,
      lang: "en",
      required: true,
    },
    {
      id: "fp_q4",
      name: "Memory of event",
      type: "categoricalLong",
      value: [
        { label: "1", value: "1" },
        { label: "2", value: "2" },
        { label: "3", value: "3" },
        { label: "4", value: "4" },
        { label: "5", value: "5" },
        { label: "6", value: "6" },
        { label: "7", value: "7" },
      ],
      labelStart: "Negative",
      labelEnd: "Positive",
      description: "My emotions related to the memory are:",
      parent: "fp",
      order: 3,
      lang: "en",
      required: false,
    },
  ],
  recordings: [
    {
      id: "fp_q1",
      name: "Future projection recording",
      type: "text",
      media: undefined,
      description: "Orally recall the 1st event.",
      lang: "en",
    },
  ],
}

export default futureProjectionEN
