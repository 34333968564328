import { Categorical, MedicalQuestion } from "../../../../@types/medicalQuestion"

const category: Categorical[] = [
  { label: "0", value: "0" },
  { label: "1", value: "1" },
  { label: "2", value: "2" },
  { label: "3", value: "3" },
  { label: "4", value: "4" },
  { label: "5", value: "5" },
]

const catEN: MedicalQuestion[] = [
  {
    id: "cat_intro",
    name: "COPD assessment test",
    value:
      "This questionnaire will help you and your healthcare professional measure the impact COPD (Chronic Obstructive Pulmonary Disease) " +
      "is having on your wellbeing and daily life. For each item below, choose the number that " +
      "best describes you currently.",
    type: "intro",
    lang: "en",
    parent: "cat",
    required: false,
  },
  {
    id: "cat_1",
    name: "COPD assessment test",
    type: "categoricalLong",
    value: category,
    labelStart: "I never cough",
    labelEnd: "I cough all the time",
    parent: "cat",
    order: 1,
    lang: "en",
    required: false,
  },
  {
    id: "cat_2",
    name: "COPD assessment test",
    type: "categoricalLong",
    value: category,
    labelStart: "I have no phlegm (mucus) in my chest at all",
    labelEnd: "My chest is completely full of phlegm (mucus)",
    parent: "cat",
    order: 2,
    lang: "en",
    required: false,
  },
  {
    id: "cat_3",
    name: "COPD assessment test",
    type: "categoricalLong",
    value: category,
    labelStart: "My chest does not feel tight at all",
    labelEnd: "My chest feels very tight",
    parent: "cat",
    order: 3,
    lang: "en",
    required: false,
  },
  {
    id: "cat_4",
    name: "COPD assessment test",
    type: "categoricalLong",
    value: category,
    labelStart: "When I walk up a hill or one flight of stairs I am not breathless",
    labelEnd: "When I walk up a hill or one flight of stairs I am very breathless",
    parent: "cat",
    order: 4,
    lang: "en",
    required: false,
  },
  {
    id: "cat_5",
    name: "COPD assessment test",
    type: "categoricalLong",
    value: category,
    labelStart: "I am not limited doing any activities at home",
    labelEnd: "I am very limited doing activities at home",
    parent: "cat",
    order: 5,
    lang: "en",
    required: false,
  },
  {
    id: "cat_6",
    name: "COPD assessment test",
    type: "categoricalLong",
    value: category,
    labelStart: "I am confident leaving my home despite my lung condition",
    labelEnd: "I am not at all confident leaving my home because of my lung condition",
    parent: "cat",
    order: 6,
    lang: "en",
    required: false,
  },
  {
    id: "cat_7",
    name: "COPD assessment test",
    type: "categoricalLong",
    value: category,
    labelStart: "I sleep soundly",
    labelEnd: "I don’t sleep soundly because of my lung condition",
    parent: "cat",
    order: 7,
    lang: "en",
    required: false,
  },
  {
    id: "cat_8",
    name: "COPD assessment test",
    type: "categoricalLong",
    value: category,
    labelStart: "I have lots of energy",
    labelEnd: "I have no energy at all",
    parent: "cat",
    order: 8,
    lang: "en",
    required: false,
  },
]
export default catEN
