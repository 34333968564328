import React, { Suspense, ElementType, lazy } from "react"
import { Navigate, useRoutes } from "react-router-dom"
import MainLayout from "../layouts/main"
import LogoOnlyLayout from "../layouts/LogoOnlyLayout"
import LoadingScreen from "../components/LoadingScreen"
import AuthGuard from "../guards/AuthGuard"
import PhoneNumber from "../pages/project/PhoneNumber"
import SelfDefiningMemoryIntro from "../pages/project/SelfDefiningMemoryIntro"
import SelfDefiningMemoryRecording from "../pages/project/SelfDefiningMemoryRecording"
import SelfDefiningMemoryQuestions from "../pages/project/SelfDefiningMemoryQuestions"
import MH6RecordVoiceIntro from "../pages/project/MH6RecordVoiceIntro"
import FutureProjectionIntro from "../pages/project/FutureProjectionIntro"
import FutureProjectionRecording from "../pages/project/FutureProjectionRecording"
import FutureProjectionQuestions from "../pages/project/FutureProjectionQuestions"
import CulturalStoryQuestions from "../pages/project/CulturalStoryQuestions"
import CulturalStoryIntro from "../pages/project/CulturalStoryIntro"

const Loadable = (Component: ElementType) => (props: any) => {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  )
}

export default function Router() {
  return useRoutes([
    {
      path: "*",
      element: <LogoOnlyLayout />,
      children: [
        { path: "500", element: <Page500 /> },
        { path: "404", element: <Page404 /> },
        { path: "403", element: <Page403 /> },
        { path: "*", element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: "editor",
      element: <Editor />,
      children: [{ element: <Editor />, index: true }],
    },
    {
      path: "logout/:id",
      element: <Logout />,
      children: [{ element: <Logout />, index: true }],
    },
    {
      path: "/",
      element: <MainLayout />,
      children: [
        { element: <Welcome />, index: true },
        {
          path: "project",
          children: [
            { element: <Welcome />, index: true },
            { path: ":id", element: <Project /> },
            { path: ":id/consent", element: <Consent /> },
            { path: ":id/usertype", element: <UserType /> },
            { path: ":id/newuser", element: <NewUser /> },
            { path: ":id/existinguser", element: <ExistingUser /> },
            { path: ":id/phonenumber", element: <PhoneNumber /> },
            { path: ":id/recording", element: <RecordVoice /> },
            { path: ":id/recordingintro", element: <RecordVoiceIntro /> },
            { path: ":id/mhsixrecordingintro", element: <MH6RecordVoiceIntro /> },
            { path: ":id/selfdefiningmemoryintro", element: <SelfDefiningMemoryIntro /> },
            { path: ":id/selfdefiningmemoryrecording", element: <SelfDefiningMemoryRecording /> },
            { path: ":id/selfdefiningmemoryquestions", element: <SelfDefiningMemoryQuestions /> },
            { path: ":id/futureprojectionintro", element: <FutureProjectionIntro /> },
            { path: ":id/futureprojectionrecording", element: <FutureProjectionRecording /> },
            { path: ":id/futureprojectionquestions", element: <FutureProjectionQuestions /> },
            { path: ":id/culturalstoryintro", element: <CulturalStoryIntro /> },
            { path: ":id/culturalstoryquestions", element: <CulturalStoryQuestions /> },
            { path: ":id/questionnaires/:questionnaire_id", element: <Questionnaire /> },
            { path: ":id/diseasequestionnaire", element: <DiseaseQuestionnaire /> },
            { path: ":id/done", element: <Done /> },
            { path: ":id/final/:patientid", element: <Final /> },
          ],
        },
        {
          path: "admin",
          children: [
            {
              path: "",
              element: (
                <AuthGuard>
                  <ListQuestionnaires />,
                </AuthGuard>
              ),
            },
            {
              path: "complete/:id",
              element: (
                <AuthGuard>
                  <CompleteQuestionnaire />,
                </AuthGuard>
              ),
            },
          ],
        },
      ],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ])
}

const Welcome = Loadable(lazy(() => import("../pages/Welcome")))
const Editor = Loadable(lazy(() => import("../pages/HtmlEditor")))
const Login = Loadable(lazy(() => import("../pages/auth/Login")))
const Logout = Loadable(lazy(() => import("../pages/auth/Logout")))

const Consent = Loadable(lazy(() => import("../pages/project/Consent")))
const UserType = Loadable(lazy(() => import("../pages/project/UserType")))
const NewUser = Loadable(lazy(() => import("../pages/project/NewUser")))
const ExistingUser = Loadable(lazy(() => import("../pages/project/ExistingUser")))
const RecordVoice = Loadable(lazy(() => import("../pages/project/RecordVoice")))
const RecordVoiceIntro = Loadable(lazy(() => import("../pages/project/RecordVoiceIntro")))
const Project = Loadable(lazy(() => import("../pages/project/Project")))
const Questionnaire = Loadable(lazy(() => import("../pages/project/Questionnaire")))
const DiseaseQuestionnaire = Loadable(lazy(() => import("../pages/project/DiseaseQuestionnaire")))
const Done = Loadable(lazy(() => import("../pages/project/Done")))
const Final = Loadable(lazy(() => import("../pages/project/Final")))

const ListQuestionnaires = Loadable(lazy(() => import("../pages/admin/ListQuestionnaires")))
const CompleteQuestionnaire = Loadable(lazy(() => import("../pages/admin/CompleteQuestionnaire")))

const Page500 = Loadable(lazy(() => import("../pages/Page500")))
const Page404 = Loadable(lazy(() => import("../pages/Page404")))
const Page403 = Loadable(lazy(() => import("../pages/Page403")))
