import { MedicalQuestionIntro } from "../../@types/medicalQuestion"
import { Typography } from "@mui/material"

type RenderMedicalQuestionIntroProps = {
  question: MedicalQuestionIntro
}

const RenderMedicalQuestionIntro = ({ question }: RenderMedicalQuestionIntroProps) => {
  return <Typography variant="h5">{question.value}</Typography>
}

export default RenderMedicalQuestionIntro
