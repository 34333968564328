import { MedicalQuestion } from "../../../../@types/medicalQuestion"

const generalFR: MedicalQuestion[] = [
  {
    id: "title_socio_demo",
    name: "Title sociodemographic questions",
    description: "Données sociodémographiques",
    type: "headerText",
    headerStyle: "h3",
    lang: "fr",
    parent: "general_info",
    order: 1,
    required: false,
  },
  {
    id: "user_id",
    name: "User ID",
    type: "freeText",
    description:
      "Merci de créer votre identifiant qui est constitué du jour de votre anniversaire et des 2 derniers" +
      " chiffres de votre numéro de téléphone.",
    info:
      "Par exemple : si vous êtes né.e le 5 janvier et que votre numéro de téléphone est le 0687574838, votre " +
      "identifiant est le 0538. Veuillez le retenir car il vous sera demandé plusieurs fois durant l'étude.",
    parent: "general_info",
    order: 2,
    lang: "fr",
    required: true,
    validateRegex: "^[0-9]*$",
  },
  {
    id: "age_minor",
    name: "Age Minor",
    type: "categoricalShort",
    value: [
      { label: "Oui", value: "yes" },
      { label: "Non", value: "no" },
    ],
    description: "Avez-vous 18 ans ou plus ?",
    parent: "general_info",
    order: 3,
    lang: "fr",
    required: true,
  },
  {
    id: "age",
    name: "Age",
    type: "number",
    minValue: 18,
    maxValue: 100,
    description: "Quel âge avez-vous ?",
    parent: "general_info",
    order: 4,
    lang: "fr",
    required: false,
    dependsOn: {
      id: "age_minor",
      values: ["yes"],
    },
  },
  {
    id: "educ",
    name: "Education level",
    type: "number",
    minValue: 1,
    maxValue: 25,
    description: "Combien d'années d'études avez-vous suivi ?",
    info:
      "Merci de compter chaque année depuis le CP. Exemple : si vous avez arrêté vos études après le Bac, le nombre " + "d'année est 12",
    parent: "general_info",
    order: 5,
    lang: "fr",
    required: true,
  },
  {
    id: "status_prof",
    name: "Professional status",
    type: "categoricalShort",
    value: [
      { label: "Employé", value: "employed" },
      { label: "Étudiant", value: "student" },
      { label: "Retraité", value: "retired" },
      { label: "Au chômage", value: "unemployed" },
      { label: "Non employé", value: "not-employed" },
    ],
    description: "Quel est votre statut professionnel ?",
    parent: "general_info",
    order: 6,
    lang: "fr",
    required: true,
  },
  {
    id: "job",
    name: "Job",
    type: "freeText",
    description: "Quelle est votre profession actuelle ?",
    parent: "general_info",
    order: 7,
    lang: "fr",
    required: true,
  },
  {
    id: "citizenship_lib",
    name: "Citizenshipt libanese",
    type: "categoricalShort",
    value: [
      { label: "Oui", value: "yes" },
      { label: "Non", value: "no" },
    ],
    description: "Êtes-vous de nationalité libanaise ?",
    parent: "general_info",
    order: 8,
    lang: "fr",
    required: true,
  },
  {
    id: "citizenship_other",
    name: "Citizenship",
    type: "freeText",
    description: "Précisez votre nationalité si vous n’êtes pas libanaise OU si vous avez une double nationalité",
    parent: "general_info",
    order: 9,
    lang: "fr",
    required: false,
    dependsOn: {
      id: "citizenship_lib",
      values: ["no"],
    },
  },
  {
    id: "residence_lib",
    name: "Residence libanese",
    type: "categoricalShort",
    value: [
      { label: "Oui", value: "yes" },
      { label: "Non", value: "no" },
    ],
    description: "Le Liban est-il votre lieu de résidence actuel ?",
    parent: "general_info",
    order: 10,
    lang: "fr",
    required: true,
  },
  {
    id: "residence_other",
    name: "Other residence",
    type: "freeText",
    description: "Si autre, précisez",
    parent: "general_info",
    order: 11,
    lang: "fr",
    required: false,
    dependsOn: {
      id: "residence_lib",
      values: ["no"],
    },
  },
  {
    id: "visit_lib",
    name: "Visits Libanon",
    type: "categoricalShort",
    value: [
      { label: "Fréquemment (>1x an)", value: "frequently-more-than-once-a-year" },
      { label: "Occasionnellement", value: "occasionally" },
      { label: "Peu fréquemment (1x 5 ans)", value: "infrequently-once-every-five-years" },
      { label: "Ne visite plus", value: "no-more-visits" },
      { label: "Sans réponse", value: "no-answer" },
      { label: "Autre", value: "other" },
    ],
    description: "À quelle fréquence visitez-vous le Liban ?",
    parent: "general_info",
    order: 12,
    lang: "fr",
    required: false,
    dependsOn: {
      id: "residence_lib",
      values: ["no"],
    },
  },
  {
    id: "visit_lib_other",
    name: "Visit Libanon other",
    type: "freeText",
    description: "Si autre, précisez",
    parent: "general_info",
    order: 13,
    lang: "fr",
    required: false,
    dependsOn: {
      id: "visit_lib",
      values: ["other"],
    },
  },
  {
    id: "diag_psy",
    name: "Diagnosed psychiatric disorder",
    type: "multi",
    value: [
      { label: "Non", value: "no" },
      { label: "Dépression", value: "depression" },
      { label: "Trouble bipolaire", value: "bipolar-disorder" },
      { label: "Trouble anxieux", value: "anxiety-disorder" },
      { label: "Schizophrénie et troubles psychotiques", value: "schiziphrenia-or-psychotic-disorders" },
      { label: "Trouble du comportement alimentaire", value: "eating-disorder" },
      { label: "Trouble lié à une substance ou trouble addictif", value: "substance-abuse-addiction" },
      { label: "Autre", value: "other" },
    ],
    description: "Avez-vous un reçu un diagnostic de trouble psychiatrique ?",
    info: "check all that apply",
    parent: "general_info",
    order: 14,
    lang: "fr",
    required: false,
  },
  {
    id: "diag_psy_other",
    name: "Diagnosed psychiatric disorder other",
    type: "freeText",
    description: "Si autre, précisez",
    parent: "general_info",
    order: 15,
    lang: "fr",
    required: false,
    dependsOn: {
      id: "diag_psy",
      values: ["other"],
    },
  },
  {
    id: "consult_psy",
    name: "Consultation psy",
    type: "categoricalShort",
    value: [
      { label: "Non", value: "no" },
      { label: "Oui, mais je ne consulte plus actuellement", value: "yes-but-i-dont-consult-anymore" },
      { label: "Oui et je consulte toujours actuellement", value: "yes-and-still-consulting" },
    ],
    description: "Avez-vous déjà consulté un.e psychologue ou un.e psychiatre ?",
    parent: "general_info",
    order: 16,
    lang: "fr",
    required: true,
  },
  {
    id: "lang",
    name: "Language",
    type: "categoricalShort",
    value: [
      { label: "Arabe", value: "arabic" },
      { label: "Français", value: "french" },
      { label: "Anglais", value: "english" },
      { label: "Other", value: "other" },
    ],
    description: "Quelle est votre langue maternelle ?",
    parent: "general_info",
    order: 17,
    lang: "fr",
    required: true,
  },
  {
    id: "lang_other",
    name: "Language other",
    type: "freeText",
    description: "Si autre, précisez",
    parent: "general_info",
    order: 18,
    lang: "fr",
    required: false,
    dependsOn: {
      id: "lang",
      values: ["other"],
    },
  },
  {
    id: "sex",
    name: "Gender",
    type: "categoricalShort",
    value: [
      { label: "Homme", value: "male" },
      { label: "Femme", value: "female" },
      { label: "Autre", value: "other" },
    ],
    description: "Quel est votre genre?",
    parent: "general_info",
    order: 19,
    lang: "fr",
    required: true,
  },
  {
    id: "sex_other",
    name: "Sex other",
    type: "freeText",
    description: "Si autre, précisez",
    parent: "general_info",
    order: 20,
    lang: "fr",
    required: false,
    dependsOn: {
      id: "sex",
      values: ["other"],
    },
  },
  {
    id: "diag_resp",
    name: "Diagnosis respiratory disease",
    type: "categoricalShort",
    value: [
      { label: "BPCO (Bronchopneumopathie Chronique Obstructive)", value: "copd" },
      { label: "Asthme", value: "asthma" },
      { label: "COVID-19 les 3 derniers mois", value: "covid" },
      { label: "Autres infections respiratoires dans les 3 derniers mois", value: "other-respiratory-infection" },
      { label: "Allergie respiratoire", value: "respiratory-allergies" },
      { label: "Fibrose pulmonaire", value: "pulmonary-fibosis" },
      { label: "Non", value: "no" },
    ],
    description: "Souffrez-vous d'une maladie respiratoire diagnostiquée ?",
    info: "Cochez la ou les réponses",
    parent: "general_info",
    order: 21,
    lang: "fr",
    required: true,
  },
  {
    id: "l_cov19",
    name: "Long covid-19",
    type: "categoricalShort",
    value: [
      { label: "Oui", value: "yes" },
      { label: "Non", value: "no" },
    ],
    description: "Présentez-vous actuellement des symptômes du COVID long ?",
    parent: "general_info",
    order: 22,
    lang: "fr",
    required: false,
    dependsOn: {
      id: "diag_resp",
      values: ["covid"],
    },
  },
  {
    id: "preg",
    name: "Pregnancy to date",
    type: "categoricalShort",
    value: [
      {
        label: "Oui",
        value: "yes",
      },
      {
        label: "Non",
        value: "no",
      },
    ],
    description: "Êtes-vous enceinte ?",
    parent: "general_info",
    order: 23,
    lang: "fr",
    required: true,
  },
  {
    id: "preg_d",
    name: "pregnancy month",
    type: "number",
    minValue: 1,
    maxValue: 9,
    description: "A quel mois de la grossesse êtes-vous ?",
    parent: "general_info",
    order: 24,
    lang: "fr",
    dependsOn: {
      id: "preg",
      values: ["yes"],
    },
    required: false,
  },
]

export default generalFR
