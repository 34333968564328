import { Box, Typography } from "@mui/material"

type RecordingTextProps = {
  text: string | undefined
  color: string | undefined
}

const RecordingText = ({ text, color }: RecordingTextProps) => {
  return (
    <Box>
      {text && (
        <Typography variant="body1" sx={{ fontSize: 18 }} align="center" color={color}>
          {text}
        </Typography>
      )}
    </Box>
  )
}

export default RecordingText
