import consents from "./consent"
import tasksRecording from "./tasks"
import { Project } from "../../@types/project"
import EMOTIONS from "./questions/emotions"
import general from "./questions/general"

const project: Project = {
  projectID: "156cdd18-80e3-48b9-a92b-88a911fe536d",
  projectName: "Speakuity E1",
  projectLocation: "Online",
  projectCreated: "07/04/2023",
  projectType: "longitudinal",
  defaultLang: "fr",
  projectLang: ["en", "fr", "de"],
  voiceRecordings: tasksRecording,
  questionnaires: [
    { questionnaire: general, optional: false },
    { questionnaire: EMOTIONS, optional: false },
  ], // EMOTIONS
  diseaseQuestions: [],
  consents: consents,
  isPatientAnsweringDiseaseQuestions: false,
  isStartWithQuestionnaires: true,
  isAskPhoneNumber: false,
  showPatientIdFinalPage: true,
  versionData: "1",
  transparenceID: "euz7u8",
}

export default project
