import { styled } from "@mui/material/styles"
import { Box, Button, Container, TextField, Typography } from "@mui/material"
import Page from "../../components/Page"
import { useEffect, useState } from "react"
import { Project } from "../../@types/project"
import projects from "../../@projects"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import usePatient from "../../hooks/usePatient"
import useLocales from "../../hooks/useLocales"
import { MedicalQuestion } from "../../@types/medicalQuestion"
import { CustomStateLocationProps } from "../../@types/patient"

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 680,
  margin: "auto",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
}))

const PhoneNumber = () => {
  const { translate, currentLang } = useLocales()

  const [project, setProject] = useState<Project>()
  const navigate = useNavigate()
  const location = useLocation()
  const { id } = useParams()
  const { patient } = usePatient()
  const [firstQuestionnaire, setFirstQuestionnaire] = useState<string>()
  const locationState = location as CustomStateLocationProps // Cheat: cast type to CustomStateLocationProps to access state parameter
  // defines if user starts filling out questionnaires or recordings first
  const locationStateStartWith = locationState.state.startWith || ""

  useEffect(() => {
    const proj = projects.find((project) => project.projectID === id)
    if (proj) {
      setProject(proj)

      const filteredQuestionsByLang = proj.questionnaires.map((questionnaire_structure) =>
        questionnaire_structure.questionnaire.filter((question) => question.lang === currentLang.value)
      )
      const allQuestionnaires: string[] = filteredQuestionsByLang
        .flatMap((questions: MedicalQuestion[]) => questions.map((question) => question.parent))
        .filter((v, i, a) => a.indexOf(v) === i)

      setFirstQuestionnaire(allQuestionnaires[0])
    } else {
      navigate("/")
    }
  }, [])

  if (!project) {
    return <></>
  } else {
    return (
      <Page title={`${project.projectName}`}>
        <Container>
          <ContentStyle sx={{ textAlign: "center" }}>
            <Box sx={{ pt: 5, pb: 5 }}>
              <Typography variant="h2">{translate("project.phoneNumber.title")}</Typography>
              <Typography align="justify" sx={{ pt: 3 }}>
                {translate("project.phoneNumber.explication")}
              </Typography>
              <Box sx={{ paddingTop: 4 }} justifyContent="center" alignContent="center" alignItems="center">
                <TextField sx={{ mb: 5 }} id="outlined-basic" label="06 xx xx xx xx" size="small" variant="outlined" />
                <Button
                  sx={{ width: "90%", height: 80, fontSize: 30 }}
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  //disabled={!consent}
                  onClick={() => {
                    if (locationStateStartWith === "questionnaires") {
                      navigate(`/project/${id}/questionnaires/${firstQuestionnaire}`)
                    } else if (locationStateStartWith === "recording") {
                      navigate(`/project/${id}/recording`)
                    } else {
                      if (project?.isStartWithQuestionnaires) {
                        navigate(`/project/${id}/questionnaires/${firstQuestionnaire}`)
                      } else {
                        navigate(`/project/${id}/recording`)
                      }
                    }
                  }}
                >
                  {translate("project.phoneNumber.continue")}
                </Button>
              </Box>
            </Box>
          </ContentStyle>
        </Container>
      </Page>
    )
  }
}

export default PhoneNumber
