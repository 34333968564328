import { Consent } from "../../../@types/consent"
/* eslint-disable */
const consentFR: Consent = {
  lang: "fr",
  text: `
<div class="page" title="Page 1">
<div class="layoutArea">
<div class="column">
<p><span style="font-size: 18.000000pt; font-family: 'Arial'; font-weight: bold;">Note d&rsquo;information aux PARTICIPANTS </span></p>
<p><br /></p>
<p><span style="font-size: 18.000000pt; font-family: 'Arial'; font-weight: bold; color: rgb(100.000000%, 60.000000%, 80.000000%);">SPEAKUITY MH2 </span></p>
<p><br /></p>
<p><span style="font-size: 12.000000pt; font-family: 'ArialMT';">Chère Madame, Cher Monsieur,<br /> AKUITY CARE vous propose de participer à la recherche non-interventionnelle </span></p>
<p><br /></p>
<p><span style="font-size: 12.000000pt; font-family: 'ArialMT';">intitulée SPEAKUITY MH2 (ci-après &laquo; </span><span style="font-size: 12.000000pt; font-family: 'Arial'; font-weight: bold;">la Recherche </span><span style="font-size: 12.000000pt; font-family: 'ArialMT';">&raquo;). </span></p>
<p><br /></p>
<p><span style="font-size: 12.000000pt; font-family: 'ArialMT';">Elle a pour titre "</span><span style="font-size: 12.000000pt; font-family: 'Arial'; font-weight: bold;">Collecte de données vocales et de données de santé pour recherche de biomarqueurs relatifs à la détresse physio-psychologique</span><span style="font-size: 12.000000pt; font-family: 'ArialMT';">" et porte le numéro d'identification national ID-RCB : 2023-A00981-44.<br /> Elle vise à répondre à la question : "Avec quel niveau de fiabilité peut-on évaluer la détresse physio-psychologique dans la voix ?". </span></p>
<p><br /></p>
<p><span style="font-size: 12.000000pt; font-family: 'ArialMT';">En participant à cette Recherche, vous confirmez : </span></p>
<ul style="list-style-type: disc;">
<li style="font-size: 12.000000pt; font-family: 'SymbolMT';">
<p><span style="font-size: 12.000000pt; font-family: 'ArialMT';">être majeur(e) </span></p>
</li>
<li style="font-size: 12.000000pt; font-family: 'SymbolMT';">
<p><span style="font-size: 12.000000pt; font-family: 'ArialMT';">être utilisateur(rice) </span><span style="font-size: 12.000000pt; font-family: 'ArialMT';">d&rsquo;un terminal connecté à internet et équipé d&rsquo;un microphone (smartphone, tablette ou ordinateur) </span></p>
</li>
<li style="font-size: 12.000000pt; font-family: 'SymbolMT';">
<p><span style="font-size: 12.000000pt; font-family: 'ArialMT';">être affilié(e) </span><span style="font-size: 12.000000pt; font-family: 'ArialMT';">ou bénéficiant d&rsquo;un régime de sécurité sociale </span></p>
</li>
<li style="font-size: 12.000000pt; font-family: 'SymbolMT';">
<p><span style="font-size: 12.000000pt; font-family: 'ArialMT';">parler </span><span style="font-size: 12.000000pt; font-family: 'ArialMT';">couramment l&rsquo;une des langues dans lesquelles l&rsquo;étude est réalisable, à savoir : le franç</span><span style="font-size: 12.000000pt; font-family: 'ArialMT';">ais, l&rsquo;allemand, l&rsquo;anglais ou l&rsquo;espagnol </span></p>
</li>
<li style="font-size: 12.000000pt; font-family: 'SymbolMT';">
<p><span style="font-size: 12.000000pt; font-family: 'ArialMT';">ne pas être privé(e) de liberté, sous tutelle ou curatelle </span></p>
</li>
<li style="font-size: 12.000000pt; font-family: 'SymbolMT';">
<p><span style="font-size: 12.000000pt; font-family: 'ArialMT';">maîtriser suffisamment le français, l&rsquo;allemand, l&rsquo;anglais, l&rsquo;espagnol ou la lecture pour lire un texte de quelques lignes ou répondre à des questionnaires simples dans ces langues </span></p>
</li>
<li style="font-size: 12.000000pt; font-family: 'SymbolMT';">
<p><span style="font-size: 12.000000pt; font-family: 'ArialMT';">ne pas avoir de lourdes difficultés d'élocution ou une élocution très altérée </span></p>
<p><br /></p>
<p><span style="font-size: 12.000000pt; font-family: 'ArialMT';">Le </span><span style="font-size: 12.000000pt; font-family: 'Arial'; font-weight: bold;">promoteur </span><span style="font-size: 12.000000pt; font-family: 'ArialMT';">de cette Recherche est AKUITY CARE </span></p>
<p><br /></p>
<p><span style="font-size: 12.000000pt; font-family: 'ArialMT';">23 rue Léon Jost 75017 Paris 0610694754 </span><span style="font-size: 12.000000pt; font-family: 'ArialMT'; color: rgb(0.000000%, 0.000000%, 100.000000%);">contact@akuity.care </span></p>
<p><br /></p>
<p><span style="font-size: 12.000000pt; font-family: 'ArialMT';">AKUITY CARE est à l'initiative de cette Recherche, la finance, est l'auteur de son </span><span style="font-size: 12.000000pt; font-family: 'ArialMT';">protocole et est garant de sa mise en &oelig;uvre conformément aux lois et réglementa</span><span style="font-size: 12.000000pt; font-family: 'ArialMT';">tions en vigueur. </span></p>
<p><br /></p>
<p><span style="font-size: 12.000000pt; font-family: 'ArialMT';">L'</span><span style="font-size: 12.000000pt; font-family: 'Arial'; font-weight: bold;">investigateur principal </span><span style="font-size: 12.000000pt; font-family: 'ArialMT';">de cette Recherche est Dr. Guy Fagherazzi </span></p>
<p><span style="font-size: 12.000000pt; font-family: 'ArialMT';">Docteur en Epidémiologie et Biostatistiques </span></p>
<p><span style="font-size: 12.000000pt; font-family: 'ArialMT';">Directeur du Département Precision Health du Luxembourg Institute of Health
<p><br /></p>
 Le Dr. Guy Fagherazzi est garant de l'analyse des données conformément au protocole et en guidera les aspects méthodologiques. </span></p>
</li>
</ul>
</div>
</div>
</div>

<p><span style="font-size: 12.000000pt; font-family: 'ArialMT';">AKUITY CARE envisage de développer une solution d&rsquo;Intelligence Artificielle (IA) permettant, à partir d&rsquo;une analyse de la voix, de détecter et de mesurer des émotions, </span><span style="font-size: 12.000000pt; font-family: 'ArialMT';">des symptômes ou des maladies. Les algorithmes d'IA qui seraient à terme intégrés dans une telle solution sont qualifiés de </span><span style="font-size: 12.000000pt; font-family: 'Arial'; font-weight: bold;">biomarqueurs vocaux</span><span style="font-size: 12.000000pt; font-family: 'ArialMT';">. </span></p>
<p><br /></p>
<p><span style="font-size: 12.000000pt; font-family: 'ArialMT';">Cette future solution nécessite au préalable la collecte de données de voix de </span><span style="font-size: 12.000000pt; font-family: 'ArialMT';">participants. AKUITY CARE souhaite ainsi mettre en &oelig;uvre une recherche non</span><span style="font-size: 12.000000pt; font-family: 'ArialMT';">- </span><span style="font-size: 12.000000pt; font-family: 'ArialMT';">interventionnelle, c&rsquo;est</span><span style="font-size: 12.000000pt; font-family: 'ArialMT';">-à-dire, dans l'éventualité où vous seriez actuellement pris en charge médicalement, sans aucune modification de cette prise en charge. </span></p>
<p><br /></p>
<p><span style="font-size: 12.000000pt; font-family: 'ArialMT';">Cette Recherche porte sur la collecte de votre voix par des enregistrements vocaux basés sur des tâches qui vous seront expliquées dans l'interface de collecte, ainsi que sur la collecte de réponses à des questionnaires qui vous seront soumis dans cette même interface. </span></p>
<p><br /></p>
<p><span style="font-size: 12.000000pt; font-family: 'Arial'; font-weight: bold;">La présente note d&rsquo;information a pour objet de vous exposer les </span><span style="font-size: 12.000000pt; font-family: 'Arial'; font-weight: bold;">caractéristiques de la Recherche ainsi que les conditions dans lesquelles vos données sont traitées et sécurisées, et de porter à votre connaissance </span><span style="font-size: 12.000000pt; font-family: 'Arial'; font-weight: bold;">l&rsquo;ensemble de vos droits. </span></p>
<p><br /></p>
<p><span style="font-size: 12.000000pt; font-family: 'Arial'; font-weight: bold;">I. Présentation de la Recherche </span></p>
<p><br /></p>
<p><span style="font-size: 12.000000pt; font-family: 'ArialMT';">La Recherche est réalisée et financée par AKUITY CARE en sa qualité de promoteur. </span><span style="font-size: 12.000000pt; font-family: 'Arial'; font-weight: bold;">Objectif de la Recherche </span><span style="font-size: 12.000000pt; font-family: 'ArialMT';">: </span></p>
<p><span style="font-size: 12.000000pt; font-family: 'ArialMT';">L&rsquo;objectif principal de la Recherche est de développer un biomarqueur vocal estimant </span><span style="font-size: 12.000000pt; font-family: 'ArialMT';">les </span><span style="font-size: 12.000000pt; font-family: 'Arial'; font-weight: bold;">symptômes dépressifs </span><span style="font-size: 12.000000pt; font-family: 'ArialMT';">à partir de la voix.<br /> A titre d'objectifs secondaires, la Recherche permettra de mesurer le niveau de performance de différents biomarqueurs vocaux entraînés sur les données collectées </span><span style="font-size: 12.000000pt; font-family: 'ArialMT';">et visant l&rsquo;estimation des troubles suivants à partir de la voix : </span></p>
<ul style="list-style-type: none;">
<li>
<p><span style="font-size: 12.000000pt; font-family: 'ArialMT';">- &nbsp;Les </span><span style="font-size: 12.000000pt; font-family: 'Arial'; font-weight: bold;">troubles anxieux </span></p>
</li>
<li>
<p><span style="font-size: 12.000000pt; font-family: 'ArialMT';">- &nbsp;Les </span><span style="font-size: 12.000000pt; font-family: 'Arial'; font-weight: bold;">symptômes de fatigue </span></p>
</li>
<li>
<p><span style="font-size: 12.000000pt; font-family: 'ArialMT';">- &nbsp;Le </span><span style="font-size: 12.000000pt; font-family: 'Arial'; font-weight: bold;">stress </span></p>
</li>
<li>
<p><span style="font-size: 12.000000pt; font-family: 'ArialMT';">- &nbsp;La </span><span style="font-size: 12.000000pt; font-family: 'Arial'; font-weight: bold;">douleur</span><span style="font-size: 12.000000pt; font-family: 'ArialMT';">. </span></p>
<p><br /></p>
<p><span style="font-size: 12.000000pt; font-family: 'ArialMT';">A titre d'objectif secondaire également, la Recherche permettra de mesurer le niveau de performance de biomarqueurs vocaux portant sur les </span><span style="font-size: 12.000000pt; font-family: 'Arial'; font-weight: bold;">émotions </span><span style="font-size: 12.000000pt; font-family: 'ArialMT';">et de tester ces biomarqueurs en les intégrant périodiquement dans une application développée par le promoteur. </span></p>
<p><br /></p>
<p><span style="font-size: 12.000000pt; font-family: 'Arial'; font-weight: bold;">Durée </span><span style="font-size: 12.000000pt; font-family: 'ArialMT';">: </span></p>
<p><br /></p>
<p><span style="font-size: 12.000000pt; font-family: 'ArialMT';">Votre participation à la Recherche prendra environ une quinzaine de minutes. La Recherche globale durera 5 ans et devrait permettre la participation d'environ 30 000 participants. </span></p>
<p><br /></p>
<p><span style="font-size: 12.000000pt; font-family: 'Arial'; font-weight: bold;">Méthodologie </span><span style="font-size: 12.000000pt; font-family: 'ArialMT';">:<br /> Deux canaux de recrutement sont utilisés pour cette Recherche : </span></p>
<p><span style="font-size: 12.000000pt; font-family: 'Arial'; font-weight: bold;">Canal #1 : site internet </span><span style="font-size: 11.000000pt; font-family: 'Arial'; font-weight: bold; color: rgb(6.670000%, 33.300000%, 80.000000%);">http://www.yourvoice.care/<br /> </span><span style="font-size: 12.000000pt; font-family: 'ArialMT';">Tous les participants visitant ce site internet, mis en place par le promoteur pour la Recherche, se verront proposer la participation à la </span><span style="font-size: 12.000000pt; font-family: 'ArialMT';">Recherche sur un principe d&rsquo;auto</span><span style="font-size: 12.000000pt; font-family: 'ArialMT';">- </span><span style="font-size: 12.000000pt; font-family: 'ArialMT';">inclusion s&rsquo;ils répondent aux critères d&rsquo;inclusion. </span></p>
<p><span style="font-size: 12.000000pt; font-family: 'Arial'; font-weight: bold;">Canal #2 : application bien-être gratuite </span></p>
<p><span style="font-size: 12.000000pt; font-family: 'ArialMT';">Le promoteur a développé une application bien-être gratuite permettant notamment à ses utilisateurs d'exprimer leurs émotions.<br /> Tous les utilisateurs cette application se verront proposer la participation à la </span><span style="font-size: 12.000000pt; font-family: 'ArialMT';">Recherche sur un principe d&rsquo;auto</span><span style="font-size: 12.000000pt; font-family: 'ArialMT';">-</span><span style="font-size: 12.000000pt; font-family: 'ArialMT';">inclusion s&rsquo;ils répondent aux critères d&rsquo;inclusion. </span></p>
<p><br /></p>
<p><span style="font-size: 12.000000pt; font-family: 'ArialMT';">Les données collectées dans la Recherche permettront d'entraîner des algorithmes d'Intelligence Artificielle (IA) constituant les biomarqueurs vocaux évoqués dans "Objectif de la Recherche". </span></p>
<p><span style="font-size: 12.000000pt; font-family: 'ArialMT';">Un algorithme d&rsquo;IA </span><span style="font-size: 12.000000pt; font-family: 'ArialMT';">est un code informatique ayant pour but de faire une estimation automatique à partir de données d'entrée. Ici, l'estimation porte sur des symptômes, </span><span style="font-size: 12.000000pt; font-family: 'Arial'; font-weight: bold;">données de sortie </span><span style="font-size: 12.000000pt; font-family: 'ArialMT';">des algorithmes, et elle est faite à partir d'enregistrements vocaux, </span><span style="font-size: 12.000000pt; font-family: 'Arial'; font-weight: bold;">données d'entrée </span><span style="font-size: 12.000000pt; font-family: 'ArialMT';">des algorithmes.<br /> Le développement de ces algorithmes repose sur un principe d'apprentissage : des données d'entrée et de sortie sont fournies à l'algorithme, en grand nombre, pour affiner sa capacité à faire l'estimation qui en est attendue. En complément des données d'entrée et de sortie, d'autres données sont fournies à l'algorithme pour l'aider à faire son estimation. </span></p>
<p><span style="font-size: 12.000000pt; font-family: 'ArialMT';">Les données qui vous seront demandées dans la Recherche ont été choisies car elles sont toutes présumées importantes pour l'apprentissage des algorithmes visés dans les objectifs de la Recherche. </span></p>
<p><br /></p>
<p><span style="font-size: 12.000000pt; font-family: 'Arial'; font-weight: bold;">Bénéfices attendus </span><span style="font-size: 12.000000pt; font-family: 'ArialMT';">:<br /> La réalisation de cette Recherche permettra de contribuer au développement des biomarqueurs vocaux précités.<br /> </span><span style="font-size: 12.000000pt; font-family: 'ArialMT';">Aucune indemnité n&rsquo;est prévue pour votre participation à cette Recherche. </span></p>
<p><br /></p>
<p><span style="font-size: 12.000000pt; font-family: 'Arial'; font-weight: bold;">Contraintes </span><span style="font-size: 12.000000pt; font-family: 'ArialMT';">: </span></p>
<p><span style="font-size: 12.000000pt; font-family: 'ArialMT';">Sauf opposition de votre part, votre participation à la Recherche impliquera de fournir les données nécessaires à l'atteinte des objectifs de la Recherche, en répondant à plusieurs questionnaires sur votre profil, vos habitudes et vos données de santé, ainsi que de réaliser quelques tâches vocales. </span></p>
<p><br /></p>
<p><span style="font-size: 12.000000pt; font-family: 'Arial'; font-weight: bold;">Risques prévisibles </span><span style="font-size: 12.000000pt; font-family: 'ArialMT';">:<br /> </span><span style="font-size: 12.000000pt; font-family: 'ArialMT';">La participation à la Recherche n&rsquo;importe aucun risque supplémentaire. </span></p>
<p><br /></p>
<p><span style="font-size: 12.000000pt; font-family: 'Arial'; font-weight: bold;">Quels sont vos droits ? </span></p>
<p><span style="font-size: 12.000000pt; font-family: 'ArialMT';">Votre participation à cette Recherche est entièrement libre et volontaire. Vous pouvez décider de refuser de participer à la Recherche ou interrompre votre collaboration à tout moment, sans justification et sans conséquence sur votre prise en charge éventuelle. </span></p>
<p><br /></p>
<p><span style="font-size: 12.000000pt; font-family: 'ArialMT';">Nous vous invitons à poser à AKUITY CARE les questions que vous pourriez avoir à propos de cette Recherche, en utilisant l'adresse email suivante : contact@akuity.care </span></p>
<p><br /></p>
<p><span style="font-size: 12.000000pt; font-family: 'ArialMT';">Cette Recherche ne nécessite ni consultation, ni prescription ou examen particulier. </span></p>
<p><br /></p>
<p><span style="font-size: 12.000000pt; font-family: 'ArialMT';">A l&rsquo;issue de la Recherche, vous pourrez, si vous le souhaitez, prendre connaissance de ses résultats globaux en sollicitant AKUITY CARE, conformément à l&rsquo;article </span><span style="font-size: 12.000000pt; font-family: 'ArialMT';">L.1122- 1 du Code de la Santé Publique. </span></p>
<p><br /></p>
<p><span style="font-size: 12.000000pt; font-family: 'Arial'; font-weight: bold;">II. Vos données à caractère personnel dans le cadre de la Recherche </span></p>
<p><br /></p>
<p><span style="font-size: 12.000000pt; font-family: 'ArialMT';">Votre participation à la Recherche implique un traitement de vos données personnelles en application : </span></p>
<ul style="list-style-type: none;">
<li>
<p><span style="font-size: 12.000000pt; font-family: 'ArialMT';">- &nbsp;De la loi n&deg; 78-17 du 6 janvier 1978 </span><span style="font-size: 12.000000pt; font-family: 'ArialMT';">modifiée relative à l&rsquo;informatique, aux </span><span style="font-size: 12.000000pt; font-family: 'ArialMT';">fichiers et aux libertés, modifiée, </span></p>
</li>
<li>
<p><span style="font-size: 12.000000pt; font-family: 'ArialMT';">- &nbsp;Du Règlement UE 2016/679 relatif à la protection des personnes physiques à </span><span style="font-size: 12.000000pt; font-family: 'ArialMT';">l&rsquo;égard du traitement des données à caractère personnel et à la libre circulation </span><span style="font-size: 12.000000pt; font-family: 'ArialMT';">de ces données du 27 avril 2016 (ci-après &laquo; </span><span style="font-size: 12.000000pt; font-family: 'Arial'; font-weight: bold;">Le RGPD </span><span style="font-size: 12.000000pt; font-family: 'ArialMT';">&raquo;). </span></p>
<p><span style="font-size: 12.000000pt; font-family: 'ArialMT';">(ci-après &laquo; la </span><span style="font-size: 12.000000pt; font-family: 'Arial'; font-weight: bold;">Réglementation </span><span style="font-size: 12.000000pt; font-family: 'ArialMT';">&raquo;). </span>
<p><br /></p>

<span style="font-size: 12.000000pt; font-family: 'Arial'; font-weight: bold;">Responsable de traitement : </span></p>
<p><span style="font-size: 12.000000pt; font-family: 'ArialMT';">Vous êtes informé que AKUITY CARE, qui peut être contacté aux coordonnées suivantes: 23 rue Léon Jost 75017 Paris, 0610694754, contact@akuity.care, est le responsable de traitement de vos données à caractère personnel pour les besoins de </span><span style="font-size: 12.000000pt; font-family: 'ArialMT';">la mise en &oelig;uvre de la Recherche conduite selon les modalités exposées ci</span><span style="font-size: 12.000000pt; font-family: 'ArialMT';">-dessus. </span></p>
<p><br /></p>
<p><span style="font-size: 12.000000pt; font-family: 'Arial'; font-weight: bold;">Finalité du traitement : </span></p>
<p><span style="font-size: 12.000000pt; font-family: 'ArialMT';">Vos données personnelles sont traitées aux fins de développement des biomarqueurs vocaux précités. Dans ce cadre, les sous-finalités sont les suivantes : élaborer le registre des participants, assurer la sécurité des données, tester leur qualité et répondre aux demandes d'exercice des droits des participants. </span></p>
<p><br /></p>
<p><span style="font-size: 12.000000pt; font-family: 'ArialMT';">Par ailleurs, vos données pourront être réutilisées dans d'autres recherches, études ou observations. Ceci permettra par exemple au promoteur de tester les biomarqueurs vocaux développés dans cette étude sur les données issues d'une autre étude. Vous pouvez à tout moment accéder à la liste de ces recherches sur un portail transparence dédié et, si vous le souhaitez, exercer vos droits. Ce portail est accessible à l'adresse suivante </span><span style="font-size: 12.000000pt; font-family: 'ArialMT'; color: rgb(0.000000%, 0.000000%, 100.000000%);">www.akuity.care/transparence/nv92j2</span><span style="font-size: 12.000000pt; font-family: 'ArialMT';">. </span></p>
<p><br /></p>
<p><span style="font-size: 12.000000pt; font-family: 'Arial'; font-weight: bold;">Base légale du traitement : </span></p>
<p><span style="font-size: 12.000000pt; font-family: 'ArialMT';">Ce traitement répond à un intérêt légitime (article 6.1.f. du RGPD) poursuivi par AKUITY CARE à des fins de recherche scientifique (article 9.2.j. du RGPD). </span></p>
<p><span style="font-size: 12.000000pt; font-family: 'ArialMT';">L&rsquo;intérêt légitime poursuivi est l&rsquo;amélioration de ses connaissances aux fins de </span><span style="font-size: 12.000000pt; font-family: 'ArialMT';">développer les biomarqueurs vocaux précités. Le développement de ces biomarqueurs requiert en effet de manière indispensable l'ensemble des données collectées dans cette Recherche. </span></p>
<p><span style="font-size: 12.000000pt; font-family: 'ArialMT';">Par ailleurs, le traitement mis en &oelig;uvre dans le cadre de la Recherche est conforme à </span><span style="font-size: 12.000000pt; font-family: 'ArialMT';">la méthodologie de référence MR-003 élaborée par la Commission Nationale de </span><span style="font-size: 12.000000pt; font-family: 'ArialMT';">l&rsquo;Informatique et Libertés (CNIL). </span></p>
<p><br /></p>
<p><span style="font-size: 12.000000pt; font-family: 'Arial'; font-weight: bold;">Données personnelles concernées : </span></p>
<p><span style="font-size: 12.000000pt; font-family: 'ArialMT';">Sauf opposition de votre part au titre de votre participation à la Recherche, vous êtes informé que les catégories de données ci-après détaillées, seront collectées et traitées dans des conditions de sécurité adaptées : </span></p>
<p><br /></p>
<p><span style="font-size: 12.000000pt; font-family: 'ArialMT';">Données de santé :</span></p>
    <ul>
        <li><ul>
                <li><p><span style="font-size: 12.000000pt; font-family: 'ArialMT';">Enregistrements de voix (par des tâches vocales) </span></p></li>
                <li><p><span style="font-size: 12.000000pt; font-family: 'ArialMT';">Evaluation des symptômes dépressifs (par un questionnaire) </span></p></li>
                <li><p><span style="font-size: 12.000000pt; font-family: 'ArialMT';">Evaluation des troubles anxieux (par un questionnaire) </span></p></li>
                <li><p><span style="font-size: 12.000000pt; font-family: 'ArialMT';">Evaluation des symptômes de fatigue (par un questionnaire) </span></p></li>
                <li><p><span style="font-size: 12.000000pt; font-family: 'ArialMT';">Evaluation du stress (par une échelle) </span></p></li>
                <li><p><span style="font-size: 12.000000pt; font-family: 'ArialMT';">Evaluation de la douleur (par une échelle) </span></p></li>
                <li><p><span style="font-size: 12.000000pt; font-family: 'ArialMT';">Émotions ressenties sur l&rsquo;instant et intensité (à choisir dans une liste) </span></p></li>
                <li><p><span style="font-size: 12.000000pt; font-family: 'ArialMT';">Diagnostics connus de dépression, trouble anxieux généralisé, schizophrénie, troubles bipolaires, BPCO, asthme, COVID-19 récent / COVID-19 &quot;long&quot;, infections respiratoires actuelles ou récentes, symptômes ORL actuels (à indiquer dans une liste), allergies respiratoires, fibrose pulmonaire, cancer (et localisation, traitement, épisode, date du diagnostic si applicables) </span></p></li>
                <li><p><span style="font-size: 12.000000pt; font-family: 'ArialMT';">Autres comorbidités connues (à indiquer dans une liste) </span></p></li>
                <li><p><span style="font-size: 12.000000pt; font-family: 'ArialMT';">Traitements en cours (à indiquer dans une liste) </span></p></li>
                <li><p><span style="font-size: 12.000000pt; font-family: 'ArialMT';">Données issues de votre terminal (smartphone, ordinateur ou tablette) nécessaires à l&rsquo;interprétation du signal vocal (type, marque et modèle duterminal utilisé, nom et version du système d&rsquo;exploitation, nom et versiondu navigateur)</p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;<ul>
        
<p><span style="font-size: 12.000000pt; font-family: 'ArialMT';">Données générales :</span></p>
        
        <li><ul>
                <li><p><span style="font-size: 12.000000pt; font-family: 'ArialMT';">Langue maternelle</span></p></li>
                <li><p><span style="font-size: 12.000000pt; font-family: 'ArialMT';">Année de naissance</span></p></li>
                <li><p><span style="font-size: 12.000000pt; font-family: 'ArialMT';">Sexe</span></p></li>
                <li><p><span style="font-size: 12.000000pt; font-family: 'ArialMT';">Taille</span></p></li>
                <li><p><span style="font-size: 12.000000pt; font-family: 'ArialMT';">Poids</span></p></li>
                <li><p><span style="font-size: 12.000000pt; font-family: 'ArialMT';">Consommation actuelle de tabac ou autres substancesfumées (fréquence, quantité par forme, ancienneté)</span></p></li>
                <li><p><span style="font-size: 12.000000pt; font-family: 'ArialMT';">Consommation historique de tabac ou autres substances fumées (fréquence, quantité par forme, durée, date de l&apos;arrêt) &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</span></p></li>
                <li><p><span style="font-size: 12.000000pt; font-family: 'ArialMT';">Consommation historique d&apos;alcool (fréquence et quantité)</span></p></li>
                <li><p><span style="font-size: 12.000000pt; font-family: 'ArialMT';">Grossesse en cours (et stade si applicable)</span></p></li>
                <li><p><span style="font-size: 12.000000pt; font-family: 'ArialMT';">Données relatives à la conduite de la recherche (horodatage)</span></p></li>
                <li><p><span style="font-size: 12.000000pt; font-family: 'ArialMT';">Code identifiant unique non-signifiant (votre identifiant pour cette Recherche issu du cryptage de votre adresse email &ndash; explication fournie ci-après)</span></p></li>
                <li><p><span style="font-size: 12.000000pt; font-family: 'ArialMT';">Mot de passe associé à votre compte si vous participez à la Recherche via le canal #2 (application bien-être)</span></p></li>
                <li><p><span style="font-size: 12.000000pt; font-family: 'ArialMT';">Affiliation à un régime de sécurité sociale</span></p></li>
            </ul>
        </li>
    </ul>
    <p><br></p>
</div><p><span style="font-size: 12.000000pt; font-family: 'ArialMT';">Ces informations seront collectées directement auprès de vous et du terminal à partir duquel vous participez à la Recherche.<br /> Des questionnaires seront affichés dans l'interface de collecte et vous les remplirez par écrans successifs, sur le site internet de la Recherche (canal #1) ou dans l'application bien-être (canal #2).<br /> Quel que soit le canal utilisé, les tâches vocales vous seront expliquées par des consignes affichées sur l'écran. Vous les effectuerez en parlant dans votre terminal (smartphone, ordinateur ou tablette), après avoir activé le microphone et en cliquant sur un bouton d'enregistrement. Par exemple, l'une de ces tâches consistera à répondre librement et par oral à la question : "</span><span style="font-size: 12.000000pt; font-family: 'Arial'; font-style: italic;">Comment ça va aujourd&rsquo;hui ?</span><span style="font-size: 12.000000pt; font-family: 'ArialMT';">" </span></p>
<p><br /></p>
<p><span style="font-size: 12.000000pt; font-family: 'ArialMT';">Si vous participez à l'étude via le canal #2 (application bien-être), vous consentez par ailleurs à ce que les éventuelles données collectées dans cette application avant votre participation à la Recherche rejoignent les données exploitées dans le cadre de la Recherche. </span></p>
<p><br /></p>
<p><span style="font-size: 12.000000pt; font-family: 'ArialMT';">Vous êtes informé(e) que vous serez identifié(e) au sein du fichier de la Recherche par un </span><span style="font-size: 12.000000pt; font-family: 'Arial'; font-weight: bold;">code non-signifiant </span><span style="font-size: 12.000000pt; font-family: 'ArialMT';">(c'est-à-dire ne traduisant par directement votre identité), </span><span style="font-size: 12.000000pt; font-family: 'Arial'; font-weight: bold;">associé à votre adresse email de manière unique</span><span style="font-size: 12.000000pt; font-family: 'ArialMT';">. Votre adresse email vous sera en effet demandée au début de la Recherche mais </span><span style="font-size: 12.000000pt; font-family: 'Arial'; font-weight: bold;">elle ne sera pas accessible au promoteur </span><span style="font-size: 12.000000pt; font-family: 'ArialMT';">car elle sera transformée dans sa base en ce code unique non-signifiant par une technique de cryptage. Ceci permettra au promoteur de relier entre elles toutes les données qui vous concernent, en particulier si vous participez plusieurs fois à la Recherche. Votre participation répétée est en effet possible si vous vous identifiez par votre adresse email sur le site internet (canal #1) ou lorsque vous vous authentifierez dans l'application (canal #2). </span></p>
<p><br /></p>
<p><span style="font-size: 12.000000pt; font-family: 'ArialMT';">C'est par ailleurs votre adresse email qui permettra au promoteur d'agir sur vos données si vous le lui demandez conformément à vos droits. </span></p>
<p><br /></p>
<p><span style="font-size: 12.000000pt; font-family: 'ArialMT';">Les tâches vocales de cette Recherche comprennent certaines </span><span style="font-size: 12.000000pt; font-family: 'ArialMT';">tâches d&rsquo;expr</span><span style="font-size: 12.000000pt; font-family: 'ArialMT';">ession libre. Même si la nature des tâches ne vous y incitera pas, veuillez svp prendre garde à </span><span style="font-size: 12.000000pt; font-family: 'Arial'; font-weight: bold;">ne divulguer aucune information identifiante (comme votre nom ou vos coordonnées) dans vos réponses aux tâches vocales</span><span style="font-size: 12.000000pt; font-family: 'ArialMT';">. De telles informations nous </span><span style="font-size: 12.000000pt; font-family: 'ArialMT';">obligeront à détruire vos données et ne permettront pas votre participation à l&rsquo;étude. </span></p>
<p><br /></p>
<p><span style="font-size: 12.000000pt; font-family: 'ArialMT';">En aucun cas et de quelque manière que ce soit AKUITY CARE en sa qualité de </span><span style="font-size: 12.000000pt; font-family: 'ArialMT';">promoteur n&rsquo;aura accès à vos données directement identifiantes. Vous serez </span><span style="font-size: 12.000000pt; font-family: 'ArialMT';">identifié(e) dans les fichiers par le code non-signifiant évoqué précédemment. </span></p>
<p><br /></p>
<p><span style="font-size: 12.000000pt; font-family: 'ArialMT';">Par ailleurs, les résultats de la Recherche ne pourront en aucun cas permettre votre identification directe ou indirecte. Ils pourront être présentés dans des journaux médicaux et aux autorités compétentes. </span></p>
<p><br /></p>
<p><span style="font-size: 12.000000pt; font-family: 'ArialMT';">Enfin, ces données seront traitées dans une base de données informatisée présentant toutes les mesures de sécurité techniques et organisationnelles requises et de manière confidentielle, dans le respect du secret professionnel. </span></p>
<p><br /></p>
<p><span style="font-size: 12.000000pt; font-family: 'Arial'; font-weight: bold;">Destinataires : </span></p>
<p><span style="font-size: 12.000000pt; font-family: 'ArialMT';">Sauf opposition de votre part et afin de s'assurer de la qualité de la Recherche et, notamment, de l'authenticité et la cohérence des informations recueillies dans ce cadre, des personnes habilitées par AKUITY CARE sont </span><span style="font-size: 12.000000pt; font-family: 'ArialMT';">susceptibles d&rsquo;accéder à vos </span><span style="font-size: 12.000000pt; font-family: 'ArialMT';">données dans le respect des conditions de sécurité et de confidentialité renforcées. </span></p>
<p><br /></p>
<p><span style="font-size: 12.000000pt; font-family: 'ArialMT';">Peuvent accéder à vos données pseudonymisées (c&rsquo;est</span><span style="font-size: 12.000000pt; font-family: 'ArialMT';">-à-dire </span><span style="font-size: 12.000000pt; font-family: 'Arial'; font-weight: bold;">non directement identifiantes</span><span style="font-size: 12.000000pt; font-family: 'ArialMT';">) dans les limites de leurs habilitations au regard de leur fonction, les catégories de personnes suivantes soumises au secret professionnel : </span></p>
<ul style="list-style-type: none;">
<li>
<p><span style="font-size: 12.000000pt; font-family: 'ArialMT';">- &nbsp;AKUITY CARE en qualité de responsable de traitement et les personnes agissant pour son compte ; </span></p>
</li>
<li>
<p><span style="font-size: 12.000000pt; font-family: 'ArialMT';">- &nbsp;l</span><span style="font-size: 12.000000pt; font-family: 'ArialMT';">&rsquo;investigateur </span><span style="font-size: 12.000000pt; font-family: 'ArialMT';">principal, garant de l'analyse des données conformément au protocole et orientant les aspects méthodologiques de cette analyse </span></p>
</li>
<li>
<p><span style="font-size: 12.000000pt; font-family: 'ArialMT';">- &nbsp;le personnel d'AKUITY CARE en charge de la Recherche, dont un statisticien </span><span style="font-size: 12.000000pt; font-family: 'ArialMT';">d&rsquo;AKUITY CARE </span><span style="font-size: 12.000000pt; font-family: 'ArialMT';">; </span></p>
</li>
<li>
<p><span style="font-size: 12.000000pt; font-family: 'ArialMT';">- &nbsp;les sous-traitants en charge des prestations techniques dans la limite de leur mission technique. OVHCloud, en tant qu&rsquo;hébergeur de données de santé </span><span style="font-size: 12.000000pt; font-family: 'ArialMT';">certifié HDS (Hébergeur de Données de Santé), étant par ailleurs précisé que les serveurs du promoteur sont tous physiquement localisés en France ; </span></p>
<p><br /></p>
<p><span style="font-size: 12.000000pt; font-family: 'ArialMT';">Peuvent accéder à vos données directement identifiantes : </span></p>
<ul style="list-style-type: none;">
<li>
<p><span style="font-size: 12.000000pt; font-family: 'ArialMT';">- &nbsp;Le délégué à la protection des données (DPD</span><span style="font-size: 12.000000pt; font-family: 'ArialMT';">) d&rsquo;AKUITY CARE, lorsque vous êtes à l&rsquo;initiative du contact avec lui </span><span style="font-size: 12.000000pt; font-family: 'ArialMT';">; </span></p>
</li>
<li>
<p><span style="font-size: 12.000000pt; font-family: 'ArialMT';">- &nbsp;</span><span style="font-size: 12.000000pt; font-family: 'ArialMT';">Le personnel d&rsquo;autorités sanitaires et d&rsquo;autorités publiques de </span><span style="font-size: 12.000000pt; font-family: 'ArialMT';">contrôle légalement habilité. </span></p>
<p><br /></p>
<p><span style="font-size: 12.000000pt; font-family: 'Arial'; font-weight: bold;">Exercice des droits : </span></p>
<p><span style="font-size: 12.000000pt; font-family: 'ArialMT';">Dans le cadre de l&rsquo;étude, conformément aux dispositions de la loi n&deg; 78</span><span style="font-size: 12.000000pt; font-family: 'ArialMT';">-17 du 6 janvier 1978 et du règlement n&deg; 2016/679 du 27 avril 2016 (RGPD), vous disposez des droits suivants : </span></p>
</li>
</ul>
<ul style="list-style-type: none;">
<li>
<p><span style="font-size: 12.000000pt; font-family: 'ArialMT';">- &nbsp;Droit </span><span style="font-size: 12.000000pt; font-family: 'ArialMT';">d&rsquo;accès, de communication et de rectification </span><span style="font-size: 12.000000pt; font-family: 'ArialMT';">: vous pouvez solliciter </span><span style="font-size: 12.000000pt; font-family: 'ArialMT';">l&rsquo;accès à vos données et, le cas échéant, leur rectification, si elles s&rsquo;avèrent </span><span style="font-size: 12.000000pt; font-family: 'ArialMT';">inexactes ; </span></p>
</li>
<li>
<p><span style="font-size: 12.000000pt; font-family: 'ArialMT';">- &nbsp;</span><span style="font-size: 12.000000pt; font-family: 'ArialMT';">Droit à l&rsquo;oubli : vous pouvez demander l&rsquo;effacement de vos données </span><span style="font-size: 12.000000pt; font-family: 'ArialMT';">; </span></p>
</li>
<li>
<p><span style="font-size: 12.000000pt; font-family: 'ArialMT';">- &nbsp;Droit de limitation du traitement : vous pouvez demander à ce que vos données fassent l'objet d'un marquage spécifique en vue de limiter leur traitement futur, dans différentes circonstances ; </span></p>
</li>
<li>
<p><span style="font-size: 12.000000pt; font-family: 'ArialMT';">- &nbsp;</span><span style="font-size: 12.000000pt; font-family: 'ArialMT';">Droit d&rsquo;opposition </span><span style="font-size: 12.000000pt; font-family: 'ArialMT';">: vous pouvez vous opposer au traitement de vos données ; </span></p>
</li>
<li>
<p><span style="font-size: 12.000000pt; font-family: 'ArialMT';">- &nbsp;Droit de décider du devenir de vos données : vous avez la possibilité de définir des directives relatives à la conservation, à l'effacement et à la communication de vos données. </span></p>
<p><br /></p>

<p><span style="font-size: 12.000000pt; font-family: 'ArialMT';">Vous pouvez exercer ces droits auprès du délégué à la protection des données (DPD) </span><span style="font-size: 12.000000pt; font-family: 'ArialMT';">d&rsquo;AKUITY CARE, en joignant à votre demande </span><span style="font-size: 12.000000pt; font-family: 'ArialMT';">l'adresse email utilisée lors de votre participation</span><span style="font-size: 12.000000pt; font-family: 'ArialMT';">. Il s&rsquo;oblige à donner suite à vos demandes complètes dans un délai d&rsquo;un </span><span style="font-size: 12.000000pt; font-family: 'ArialMT';">(1) mois à compter de leur réception. </span></p>
<p><br /></p>
<p><span style="font-size: 12.000000pt; font-family: 'ArialMT';">Le délégué à la protection des données (DPD) </span><span style="font-size: 12.000000pt; font-family: 'ArialMT';">d&rsquo;AKUITY CARE peut être contacté </span><span style="font-size: 12.000000pt; font-family: 'ArialMT';">aux coordonnées suivantes : </span></p>
<p><br /></p>
<p><span style="font-size: 12.000000pt; font-family: 'ArialMT';">Jean-Marie Nazarenko<br /> 6 avenue de Craponne 13100 Aix-en-Provence jmn@lepoissonpilote.com<br /> +33 6 86 67 30 38 </span></p>
<p><br /></p>
<p><span style="font-size: 12.000000pt; font-family: 'ArialMT';">AKUITY CARE s&rsquo;interdit de prendre connaissance des messages, informations, </span><span style="font-size: 12.000000pt; font-family: 'ArialMT';">documents et données directement identifiantes échangées entre vous et son délégué à la protection des données (DPD). </span></p>
<p><br /></p>
<p><span style="font-size: 12.000000pt; font-family: 'ArialMT';">Si en dépit des mesures mises en place par AKUITY CARE, vous estimez que vos </span><span style="font-size: 12.000000pt; font-family: 'ArialMT';">droits n&rsquo;ont pas été respectés, vous disposez également du droit d&rsquo;introduire une </span><span style="font-size: 12.000000pt; font-family: 'ArialMT';">réclamation auprès de la CNIL : </span></p>
<p><br /></p>
<p><span style="font-size: 12.000000pt; font-family: 'ArialMT';">● </span><span style="font-size: 12.000000pt; font-family: 'ArialMT';">Via le téléservice de plainte en ligne ;<br /> </span><span style="font-size: 12.000000pt; font-family: 'ArialMT';">● </span><span style="font-size: 12.000000pt; font-family: 'ArialMT';">Par courrier postal en écrivant à : CNIL - Service des Plaintes - 3 Place de Fontenoy - TSA 80715 - 75334 PARIS CEDEX 07. </span></p>
<p><br /></p>
<p><span style="font-size: 12.000000pt; font-family: 'Arial'; font-weight: bold;">Durée de conservation : </span></p>
<p><span style="font-size: 12.000000pt; font-family: 'ArialMT';">Vos données seront conservées jusqu'à deux ans après la dernière publication des </span><span style="font-size: 12.000000pt; font-family: 'ArialMT';">résultats de la Recherche ou, en l&rsquo;absence de publication, jusqu&rsquo;à la signature du </span><span style="font-size: 12.000000pt; font-family: 'ArialMT';">rapport final de fin de la Recherche. </span></p>
<p><span style="font-size: 12.000000pt; font-family: 'ArialMT';">Elles feront l&rsquo;objet d&rsquo;un archivage pour une durée de 15 ans. </span></p>
<p><br /></p>
<p><span style="font-size: 12.000000pt; font-family: 'ArialMT';">Conformément à l&rsquo;article L. 1121</span><span style="font-size: 12.000000pt; font-family: 'ArialMT';">-4 du Code de la santé publique la Recherche a reçu un avis favorable du Comité de Protection des Personnes </span><span style="font-size: 12.000000pt; font-family: 'ArialMT';">&ndash; </span><span style="font-size: 12.000000pt; font-family: 'Arial'; font-style: italic; background-color: rgb(100.000000%, 100.000000%, 0.000000%);">[Nom du CPP] </span><span style="font-size: 12.000000pt; font-family: 'ArialMT';">le </span><span style="font-size: 12.000000pt; font-family: 'Arial'; font-style: italic;">[date de </span><span style="font-size: 12.000000pt; font-family: 'Arial'; font-style: italic; background-color: rgb(100.000000%, 100.000000%, 0.000000%);">l&rsquo;avis CPP] </span><span style="font-size: 12.000000pt; font-family: 'ArialMT';">et l&rsquo;Agence nationale de sécurité du médicament et des produits de santé </span><span style="font-size: 12.000000pt; font-family: 'ArialMT';">a </span><span style="font-size: 12.000000pt; font-family: 'ArialMT';">été informée de la mise en &oelig;uvre de la Recherche. </span></p>
<p><br /></p>
</div>
</div>
</div>
`,
  /* eslint-enable */
}

export default consentFR
