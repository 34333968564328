import { useState, useRef } from "react"

const useTimeUser = () => {
  const countRef = useRef<any>(null)
  const [timeUser, setTimeUser] = useState<number>(0)

  const handleStartTimeUser = () => {
    countRef.current = setInterval(() => {
      setTimeUser((timer) => timer + 1)
    }, 1000)
  }

  const handleResetTimeUser = () => {
    clearInterval(countRef.current)
    setTimeUser(0)
  }

  return {
    timeUser,
    handleStartTimeUser,
    handleResetTimeUser,
  }
}

export default useTimeUser
