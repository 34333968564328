import { Categorical, MedicalQuestion } from "../../../../@types/medicalQuestion"

const category: Categorical[] = [
  {
    label: "ja",
    value: "yes",
  },
  {
    label: "nein",
    value: "no",
  },
]

const respiratoryDE: MedicalQuestion[] = [
  {
    id: "asth",
    name: "Asthma",
    type: "categoricalShort",
    value: category,
    description: "Asthma Diagnose ?",
    parent: "respiratory",
    order: 1,
    lang: "de",
    required: true,
  },
  {
    id: "copd",
    name: "Chronic obstructive pulmonary disease",
    type: "categoricalShort",
    value: category,
    description: "Chronisch obstruktive Lungenerkrankung (COPD) Diagnose ?",
    parent: "respiratory",
    order: 2,
    lang: "de",
    required: true,
  },
  {
    id: "vems",
    name: "Forced expiratory volume in 1 second ",
    type: "writeNumeric",
    minValue: 500,
    maxValue: 10000,
    description: "FEV1 (mL) ?",
    parent: "respiratory",
    order: 3,
    lang: "de",
    required: true,
    doctor: true,
  },
  {
    id: "vems_z",
    name: "VEMS / Z-score",
    type: "writeNumeric",
    minValue: -20,
    maxValue: 20,
    description: "FEV1 (mL) / Z-score ?",
    parent: "respiratory",
    order: 4,
    lang: "de",
    required: false,
    doctor: true,
  },
  {
    id: "tiff",
    name: "Tiffeneau-Pinelli index",
    type: "writeNumeric",
    minValue: 0,
    maxValue: 100,
    description: "Tiffeneau Koeffizient (%) ?",
    parent: "respiratory",
    order: 4,
    lang: "de",
    required: true,
    doctor: true,
  },
  {
    id: "lung_vol",
    name: "Lung volumes",
    type: "writeNumeric",
    minValue: 500,
    maxValue: 15000,
    description: "Gesamte Lungenkapazität (mL)?",
    parent: "respiratory",
    order: 5,
    lang: "de",
    required: false,
    doctor: true,
  },
  {
    id: "lung_vol_res",
    name: "Lung volume residuel",
    type: "writeNumeric",
    minValue: 500,
    maxValue: 8000,
    description: "Restliches Lungenvolumen (mL) ?",
    parent: "respiratory",
    order: 6,
    lang: "de",
    required: false,
    doctor: true,
  },
  {
    id: "mmrc",
    name: "Scale shortness of breath mMRC",
    type: "number",
    minValue: 0,
    maxValue: 4,
    description: "mMRC Dyspnoeskala ?",
    parent: "respiratory",
    order: 7,
    lang: "de",
    required: false,
    doctor: true,
  },
  {
    id: "peak_flow",
    name: "Peak expiratory flow (L/min)",
    type: "writeNumeric",
    minValue: 0,
    maxValue: 1000,
    description: "Peak flow (L/min)",
    parent: "respiratory",
    order: 8,
    lang: "de",
    required: false,
    doctor: true,
  },
  {
    id: "raw",
    name: "Airway resistance RAW (%)",
    type: "writeNumeric",
    minValue: 0,
    maxValue: 1000,
    description: "Atemwiderstand RAW (%)",
    parent: "respiratory",
    order: 9,
    lang: "de",
    required: false,
    doctor: true,
  },
  {
    id: "exacer",
    name: "Current exacerbation",
    type: "categoricalShort",
    value: category,
    description: "Aktuelle Exazerbation ?",
    parent: "respiratory",
    order: 10,
    lang: "de",
    required: false,
    doctor: true,
  },
  {
    id: "n_exacer",
    name: "Number exacerbation over 12 last months",
    type: "number",
    minValue: 0,
    maxValue: 20,
    description: "Anzahl der Exazerbationen in den letzten 12 Monaten?",
    parent: "respiratory",
    order: 11,
    lang: "de",
    required: false,
    doctor: true,
  },
  {
    id: "date_exacer_m",
    name: "Date last exacerbation month",
    type: "select",
    value: [
      { label: "Januar", value: "january" },
      { label: "Februar", value: "february" },
      { label: "Mars", value: "march" },
      { label: "April", value: "april" },
      { label: "Mai", value: "mai" },
      { label: "Juni", value: "june" },
      { label: "Juli", value: "juli" },
      { label: "August", value: "august" },
      { label: "September", value: "september" },
      { label: "October", value: "october" },
      { label: "November", value: "november" },
      { label: "December", value: "december" },
      { label: "Fehlende Information", value: "missing-information" },
    ],
    description: "Datum der letzten Exazerbation (Monat) ?",
    parent: "respiratory",
    order: 12,
    lang: "de",
    required: false,
    doctor: true,
  },
  {
    id: "date_exacer_y",
    name: "Date last exacerbation year",
    type: "number",
    minValue: 2000,
    maxValue: 2023,
    description: "Datum der letzten Exazerbation (Jahr) ?",
    parent: "respiratory",
    order: 13,
    lang: "de",
    required: false,
    doctor: true,
  },
  {
    id: "n_hosp_exac",
    name: "Number hospitalisations due to exacerbation over 12 last months",
    type: "number",
    minValue: 0,
    maxValue: 20,
    description: "Anzahl der Krankenhausaufenthalte aufgrund von Exazerbationen in den letzten 12 Monaten?",
    parent: "respiratory",
    order: 14,
    lang: "de",
    required: false,
    doctor: true,
  },
  {
    id: "date_hosp_m",
    name: "Date last hospitalisation due to une exacerbation (month)",
    type: "select",
    value: [
      { label: "Januar", value: "january" },
      { label: "Februar", value: "february" },
      { label: "Mars", value: "march" },
      { label: "April", value: "april" },
      { label: "Mai", value: "mai" },
      { label: "Juni", value: "june" },
      { label: "Juli", value: "juli" },
      { label: "August", value: "august" },
      { label: "September", value: "september" },
      { label: "October", value: "october" },
      { label: "November", value: "november" },
      { label: "December", value: "december" },
      { label: "Fehlende Information", value: "missing-information" },
    ],
    description: "Datum des letzten Krankenhausaufenthalts aufgrund einer Exazerbation (Monat) ?",
    parent: "respiratory",
    order: 15,
    lang: "de",
    required: false,
    doctor: true,
  },
  {
    id: "date_hosp_y",
    name: "Date last hospitalisation due to une exacerbation (year)",
    type: "number",
    minValue: 2000,
    maxValue: 2023,
    description: "Datum des letzten Krankenhausaufenthalts aufgrund einer Exazerbation (Jahr) ?",
    parent: "respiratory",
    order: 16,
    lang: "de",
    required: false,
    doctor: true,
  },
]

export default respiratoryDE
