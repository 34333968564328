import { GeneralAnswers } from "../../@types/patient"
import { Dispatch, SetStateAction } from "react"
import { MedicalQuestionNumeric } from "../../@types/medicalQuestion"
import { Box, FormControl, Grid, MenuItem, Paper, Select, SelectChangeEvent, Typography } from "@mui/material"

type RenderMedicalQuestionNumericProps = {
  question: MedicalQuestionNumeric
  answersForm: GeneralAnswers[]
  setAnswersForm: Dispatch<SetStateAction<GeneralAnswers[]>>
  currentQuestionnaireID: string
}

const RenderMedicalQuestionNumeric = ({
  question,
  answersForm,
  setAnswersForm,
  currentQuestionnaireID,
}: RenderMedicalQuestionNumericProps) => {
  const handleAnswerChange = (event: SelectChangeEvent) => {
    setAnswersForm((answers: GeneralAnswers[]) => {
      const result: GeneralAnswers[] = answers.map((answer) => {
        if (answer.name === currentQuestionnaireID) {
          const updatedAnswers = answer.answers.map((answer) => {
            if (answer.id === question.id) {
              return { ...answer, answer: event.target.value }
            } else {
              return answer
            }
          })

          return { name: currentQuestionnaireID, answers: updatedAnswers, time: "" }
        } else {
          return answer
        }
      })
      return result
    })
  }

  return (
    <FormControl fullWidth sx={{ pt: 2, pb: 2, width: "100%" }} size="small">
      <Paper elevation={3}>
        <Grid container spacing={0} sx={{ width: "100%", pt: 1, pb: 1 }}>
          <Grid item xs={12} md={6} display="flex" justifyContent="center" alignContent="center" alignItems="flex-start">
            <Grid item xs={12}>
              <Box width="100%" sx={{ pt: 1 }}>
                <Typography textAlign="center">{question.description}</Typography>
              </Box>
              <Box width="100%" sx={{ pt: 1, pb: 1, pl: 5, pr: 5 }}>
                <Typography textAlign="left" style={{ fontStyle: "italic" }}>
                  {question.info}
                </Typography>
              </Box>
            </Grid>
          </Grid>

          <Grid item xs={12} md={6} display="flex" justifyContent="center" alignContent="center" alignItems="center">
            <Box width="90%" sx={{ textJustify: "left" }}>
              <Select
                id={question.id}
                value={answersForm.flatMap((answers) => answers.answers.filter((answer) => answer.id === question.id))[0].answer}
                sx={{ width: "100%" }}
                onChange={handleAnswerChange}
              >
                {Array.from({ length: question.maxValue + 1 - question.minValue }, (_, i) => (
                  <MenuItem key={i + question.minValue} value={i + question.minValue}>
                    {" "}
                    {i + question.minValue}{" "}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </FormControl>
  )
}

export default RenderMedicalQuestionNumeric
