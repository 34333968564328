export type MedicalQuestionIntro = {
  id: string
  name: string
  value: string
  type: "intro"
  lang: string
  parent: string
  dependsOn?: DependsOn
  required: boolean
  doctor?: boolean
}

export type MedicalQuestionHeaderText = {
  id: string
  name: string
  description: string
  type: "headerText"
  headerStyle: string
  lang: string
  parent: string
  order: number
  dependsOn?: DependsOn
  required: boolean
  doctor?: boolean
}

export type MedicalQuestionInstruction = {
  id: string
  name: string
  description: string
  info: string
  type: "instruction"
  lang: string
  parent: string
  order: number
  dependsOn?: DependsOn
  required: boolean
  doctor?: boolean
}

export type MedicalQuestionCategoricalShort = {
  id: string
  name: string
  type: "categoricalShort"
  value: Categorical[]
  description: string
  info?: string
  parent: string
  order: number
  lang: string
  dependsOn?: DependsOn
  required: boolean
  doctor?: boolean
}

export type MedicalQuestionCategoricalLong = {
  id: string
  name: string
  type: "categoricalLong"
  value: Categorical[]
  description?: string
  labelStart?: string
  labelEnd?: string
  parent: string
  order: number
  lang: string
  dependsOn?: DependsOn
  required: boolean
  doctor?: boolean
}

export type MedicalQuestionMulti = {
  id: string
  name: string
  type: "multi"
  value: Categorical[]
  description: string
  info?: string
  parent: string
  order: number
  lang: string
  dependsOn?: DependsOn
  required: boolean
  doctor?: boolean
}

export type MedicalQuestionNumeric = {
  id: string
  name: string
  type: "number"
  minValue: number
  maxValue: number
  description: string
  info?: string
  parent: string
  order: number
  lang: string
  dependsOn?: DependsOn
  required: boolean
  doctor?: boolean
}

export type MedicalQuestionSelect = {
  id: string
  name: string
  type: "select"
  value: Categorical[]
  description: string
  parent: string
  order: number
  lang: string
  dependsOn?: DependsOn
  required: boolean
  doctor?: boolean
}

export type MedicalQuestionWriteNumeric = {
  id: string
  name: string
  type: "writeNumeric"
  minValue: number
  maxValue: number
  description: string
  parent: string
  order: number
  lang: string
  dependsOn?: DependsOn
  required: boolean
  doctor?: boolean
}

export type MedicalQuestionFreeText = {
  id: string
  name: string
  type: "freeText"
  description: string
  info?: string
  parent: string
  order: number
  lang: string
  dependsOn?: DependsOn
  required: boolean
  doctor?: boolean
  validateRegex?: string
}

export type Categorical = {
  label: string
  value: string
  type?: string
}

export type DependsOn = {
  id: string
  values: string[]
}

export const isMedicalQuestionCategoricalShort = (question: MedicalQuestion): question is MedicalQuestionCategoricalShort => {
  return (question as MedicalQuestionCategoricalShort).type === "categoricalShort"
}

export const isMedicalQuestionCategoricalLong = (question: MedicalQuestion): question is MedicalQuestionCategoricalLong => {
  return (question as MedicalQuestionCategoricalLong).type === "categoricalLong"
}

export const isMedicalQuestionMulti = (question: MedicalQuestion): question is MedicalQuestionMulti => {
  return (question as MedicalQuestionMulti).type === "multi"
}

export const isMedicalQuestionNumeric = (question: MedicalQuestion): question is MedicalQuestionNumeric => {
  return (question as MedicalQuestionNumeric).type === "number"
}

export const isMedicalQuestionSelect = (question: MedicalQuestion): question is MedicalQuestionSelect => {
  return (question as MedicalQuestionSelect).type === "select"
}

export const isMedicalQuestionIntro = (question: MedicalQuestion): question is MedicalQuestionIntro => {
  return (question as MedicalQuestionIntro).type === "intro"
}

export const isMedicalQuestionInstruction = (question: MedicalQuestion): question is MedicalQuestionInstruction => {
  return (question as MedicalQuestionInstruction).type === "instruction"
}

export const isMedicalQuestionHeaderText = (question: MedicalQuestion): question is MedicalQuestionHeaderText => {
  return (question as MedicalQuestionHeaderText).type === "headerText"
}

export const isMedicalQuestionWriteNumeric = (question: MedicalQuestion): question is MedicalQuestionWriteNumeric => {
  return (question as MedicalQuestionWriteNumeric).type === "writeNumeric"
}

export const isMedicalQuestionFreeText = (question: MedicalQuestion): question is MedicalQuestionFreeText => {
  return (question as MedicalQuestionFreeText).type === "freeText"
}

export type MedicalQuestion =
  | MedicalQuestionCategoricalShort
  | MedicalQuestionCategoricalLong
  | MedicalQuestionNumeric
  | MedicalQuestionMulti
  | MedicalQuestionSelect
  | MedicalQuestionIntro
  | MedicalQuestionInstruction
  | MedicalQuestionHeaderText
  | MedicalQuestionWriteNumeric
  | MedicalQuestionFreeText

export type dependsOnQuestion = {
  questionnaireId: string
  question_id: string
  value: string
}

// wraps MedicalQuestions in a larger structure to enable conditional questionnaires
// e.g. show ACT only of patient gave asthma = True
export type MedicalQuestionStructure = {
  questionnaire: MedicalQuestion[]
  optional?: boolean
  dependsOnQuestion?: dependsOnQuestion
}
