import { MixedQuestionRecording } from "../../../../@types/mixedQuestionRecording"

const selfDefiningMemoryEN: MixedQuestionRecording = {
  questionnaires: [
    {
      id: "sdm_q2",
      name: "Title of event",
      type: "freeText",
      description: "Title of event",
      parent: "sdm",
      order: 1,
      lang: "en",
      required: true,
    },
    {
      id: "sdm_q3",
      name: "Title of event",
      type: "number",
      minValue: 1,
      maxValue: 100,
      description: "My age at the time of the event",
      parent: "sdm",
      order: 2,
      lang: "en",
      required: true,
    },
    {
      id: "sdm_q4",
      name: "Memory of event",
      type: "categoricalLong",
      value: [
        { label: "1", value: "1" },
        { label: "2", value: "2" },
        { label: "3", value: "3" },
        { label: "4", value: "4" },
        { label: "5", value: "5" },
        { label: "6", value: "6" },
        { label: "7", value: "7" },
      ],
      labelStart: "vague",
      labelEnd: "very detailed",
      description: "My memory of the event is:",
      parent: "sdm",
      order: 3,
      lang: "en",
      required: false,
    },
    {
      id: "sdm_q5",
      name: "My emotions",
      type: "categoricalLong",
      value: [
        { label: "1", value: "1" },
        { label: "2", value: "2" },
        { label: "3", value: "3" },
        { label: "4", value: "4" },
        { label: "5", value: "5" },
        { label: "6", value: "6" },
        { label: "7", value: "7" },
      ],
      labelStart: "Negative",
      labelEnd: "Positive",
      description: "My emotions related to the memory are:",
      parent: "sdm",
      order: 4,
      lang: "en",
      required: false,
    },
    {
      id: "sdm_q6",
      name: "My emotions intense",
      type: "categoricalLong",
      value: [
        { label: "1", value: "1" },
        { label: "2", value: "2" },
        { label: "3", value: "3" },
        { label: "4", value: "4" },
        { label: "5", value: "5" },
        { label: "6", value: "6" },
        { label: "7", value: "7" },
      ],
      labelStart: "Not intense",
      labelEnd: "Very intense",
      description: "My emotions related to the memory are:",
      parent: "sdm",
      order: 5,
      lang: "en",
      required: false,
    },
    {
      id: "sdm_q7",
      name: "event part of life",
      type: "categoricalLong",
      value: [
        { label: "1", value: "1" },
        { label: "2", value: "2" },
        { label: "3", value: "3" },
        { label: "4", value: "4" },
        { label: "5", value: "5" },
      ],
      labelStart: "Completely disagree",
      labelEnd: "Completely agree",
      description: "I feel that this event has become a part of my identity",
      parent: "sdm",
      order: 6,
      lang: "en",
      required: false,
    },
    {
      id: "sdm_q8",
      name: "event part of life",
      type: "categoricalLong",
      value: [
        { label: "1", value: "1" },
        { label: "2", value: "2" },
        { label: "3", value: "3" },
        { label: "4", value: "4" },
        { label: "5", value: "5" },
      ],
      labelStart: "Completely disagree",
      labelEnd: "Completely agree",
      description: "This event became a reference point for my own understanding of myself and the world",
      parent: "sdm",
      order: 7,
      lang: "en",
      required: false,
    },
    {
      id: "sdm_q9",
      name: "event part of life",
      type: "categoricalLong",
      value: [
        { label: "1", value: "1" },
        { label: "2", value: "2" },
        { label: "3", value: "3" },
        { label: "4", value: "4" },
        { label: "5", value: "5" },
      ],
      labelStart: "Completely disagree",
      labelEnd: "Completely agree",
      description: "I feel that this event has become a central part of my life story",
      parent: "sdm",
      order: 8,
      lang: "en",
      required: false,
    },
    {
      id: "sdm_q10",
      name: "event part of life",
      type: "categoricalLong",
      value: [
        { label: "1", value: "1" },
        { label: "2", value: "2" },
        { label: "3", value: "3" },
        { label: "4", value: "4" },
        { label: "5", value: "5" },
      ],
      labelStart: "Completely disagree",
      labelEnd: "Completely agree",
      description: "This event has colored the way I think and feel about other experiences",
      parent: "sdm",
      order: 9,
      lang: "en",
      required: false,
    },
    {
      id: "sdm_q11",
      name: "event part of life",
      type: "categoricalLong",
      value: [
        { label: "1", value: "1" },
        { label: "2", value: "2" },
        { label: "3", value: "3" },
        { label: "4", value: "4" },
        { label: "5", value: "5" },
      ],
      labelStart: "Completely disagree",
      labelEnd: "Completely agree",
      description: "This event changed my life definitively",
      parent: "sdm",
      order: 10,
      lang: "en",
      required: false,
    },
    {
      id: "sdm_q12",
      name: "event part of life",
      type: "categoricalLong",
      value: [
        { label: "1", value: "1" },
        { label: "2", value: "2" },
        { label: "3", value: "3" },
        { label: "4", value: "4" },
        { label: "5", value: "5" },
      ],
      labelStart: "Completely disagree",
      labelEnd: "Completely agree",
      description: "I often think about the effects this event will have on my future",
      parent: "sdm",
      order: 11,
      lang: "en",
      required: false,
    },
    {
      id: "sdm_q13",
      name: "event part of life",
      type: "categoricalLong",
      value: [
        { label: "1", value: "1" },
        { label: "2", value: "2" },
        { label: "3", value: "3" },
        { label: "4", value: "4" },
        { label: "5", value: "5" },
      ],
      labelStart: "Completely disagree",
      labelEnd: "Completely agree",
      description: "This event was a turning point in my life",
      parent: "sdm",
      order: 12,
      lang: "en",
      required: false,
    },
  ],
  recordings: [
    {
      id: "sdm_q1",
      name: "Free speech answer a question",
      type: "text",
      media: undefined,
      description: "Describe a memory in enough detail that it can help your friend see and feel what you experienced",
      lang: "en",
    },
  ],
}

export default selfDefiningMemoryEN
