import { Categorical, MedicalQuestion } from "../../../../@types/medicalQuestion"

const category: Categorical[] = [
  { label: "0", value: "0" },
  { label: "1", value: "1" },
  { label: "2", value: "2" },
  { label: "3", value: "3" },
  { label: "4", value: "4" },
  { label: "5", value: "5" },
]

const catFR: MedicalQuestion[] = [
  {
    id: "cat_intro",
    name: "intro",
    value:
      "Ce questionnaire vous aidera, ainsi que votre médecin, à mesurer l’impact de la BPCO sur votre bien-être et votre vie au " +
      "quotidien. Pour chaque élément ci-dessous, veuillez choissir le chiffre " +
      "qui correspond le mieux à votre état actuel.",
    type: "intro",
    lang: "fr",
    parent: "cat",
    required: false,
  },
  {
    id: "cat_1",
    name: "COPD assessment test",
    type: "categoricalLong",
    value: category,
    labelStart: "Je ne tousse jamais",
    labelEnd: "Je tousse tout le temps",
    parent: "cat",
    order: 1,
    lang: "fr",
    required: false,
  },
  {
    id: "cat_2",
    name: "COPD assessment test",
    type: "categoricalLong",
    value: category,
    labelStart: "Je n’ai pas du tout de glaire (mucus) dans les poumons",
    labelEnd: "J’ai les poumons entièrement encombrés de glaire (mucus)",
    parent: "cat",
    order: 2,
    lang: "fr",
    required: false,
  },
  {
    id: "cat_3",
    name: "COPD assessment test",
    type: "categoricalLong",
    value: category,
    labelStart: "Je n’ai pas du tout la poitrine oppressée",
    labelEnd: "J’ai la poitrine très oppressée",
    parent: "cat",
    order: 3,
    lang: "fr",
    required: false,
  },
  {
    id: "cat_4",
    name: "COPD assessment test",
    type: "categoricalLong",
    value: category,
    labelStart: "Quand je monte une côte ou une volée de marches, je ne suis pas essoufflé(e)",
    labelEnd: "Quand je monte une côte ou une volée de marches, je suis très essoufflé(e)",
    parent: "cat",
    order: 4,
    lang: "fr",
    required: false,
  },
  {
    id: "cat_5",
    name: "COPD assessment test",
    type: "categoricalLong",
    value: category,
    labelStart: "Je ne suis pas limité(e) dans mes activités chez moi",
    labelEnd: "Je suis très limité(e) dans mes activités chez moi",
    parent: "cat",
    order: 5,
    lang: "fr",
    required: false,
  },
  {
    id: "cat_6",
    name: "COPD assessment test",
    type: "categoricalLong",
    value: category,
    labelStart: "Je ne suis pas inquièt(e) quand je quitte la maison, en dépit de mes problèmes pulmonaires",
    labelEnd: "Je suis très inquièt(e) quand je quitte la maison, en raison de mes problèmes pulmonaires",
    parent: "cat",
    order: 6,
    lang: "fr",
    required: false,
  },
  {
    id: "cat_7",
    name: "COPD assessment test",
    type: "categoricalLong",
    value: category,
    labelStart: "Je dors bien",
    labelEnd: "Je dors mal à cause de mes problèmes pulmonaires",
    parent: "cat",
    order: 7,
    lang: "fr",
    required: false,
  },
  {
    id: "cat_8",
    name: "COPD assessment test",
    type: "categoricalLong",
    value: category,
    labelStart: "Je suis plein(e) d’énergie",
    labelEnd: "Je n’ai pas d’énergie du tout",
    parent: "cat",
    order: 8,
    lang: "fr",
    required: false,
  },
]

export default catFR
