import { MedicalQuestionHeaderText } from "../../@types/medicalQuestion"
import { Box, Typography, TypographyVariant } from "@mui/material"

type RenderMedicalQuestionHeaderTextProps = {
  question: MedicalQuestionHeaderText
}

const RenderMedicalQuestionHeaderText = ({ question }: RenderMedicalQuestionHeaderTextProps) => {
  return (
    <Box width="100%" sx={{ pt: 1, pb: 1, pl: 5, pr: 5 }}>
      <Typography align={"left"} variant={(question.headerStyle as TypographyVariant) || "subtitle1"}>
        {question.description}
      </Typography>
    </Box>
  )
}

export default RenderMedicalQuestionHeaderText
