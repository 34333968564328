import useLocales from "../../hooks/useLocales"
import { useNavigate, useParams } from "react-router-dom"
import projects from "../../@projects"
import { Box, Button, Container, Typography } from "@mui/material"
import Page from "../../components/Page"
import { styled } from "@mui/material/styles"
import { useEffect, useState } from "react"
import { Project } from "../../@types/project"

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 880,
  margin: "auto",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(4, 0),
}))

const CulturalStoryIntro = () => {
  const { translate } = useLocales()
  const { id } = useParams()
  const navigate = useNavigate()

  const [project, setProject] = useState<Project>()

  useEffect(() => {
    setProject(projects.find((project) => project.projectID === id))
  }, [id])

  if (!project) {
    return <></>
  } else {
    return (
      <Page title={`${project.projectName}`}>
        <Container>
          <ContentStyle>
            <Typography variant="h2" sx={{ textAlign: "center" }}>
              {translate("mh6.cs.title")}
            </Typography>
            <Typography sx={{ mt: 4, mb: 2 }}>{translate("mh6.cs.phrase1")}</Typography>
            <Box sx={{ paddingTop: 5 }} display="flex" justifyContent="center" alignContent="center" alignItems="center">
              <Button
                type="submit"
                variant="contained"
                color="primary"
                sx={{ width: "90%", height: 80, fontSize: 30 }}
                onClick={() =>
                  navigate(location.pathname.replace("culturalstoryintro", "culturalstoryquestions"), {
                    state: { currentRepetition: 1 },
                  })
                }
              >
                {translate("project.recording.instructions.continue")}
              </Button>
            </Box>
          </ContentStyle>
        </Container>
      </Page>
    )
  }
}

export default CulturalStoryIntro
